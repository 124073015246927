import { useState, useEffect } from 'react';
import React, { useRef } from 'react';
import { ethers } from 'ethers';
import ProfileCarbonCredits from './ProfileCarbonCredits';
import ProfileSBTs from './ProfileSBTs';
import NavBar from "./NavBar";
import adv from './img/advertencia.svg';
import { useTranslation } from 'react-i18next';

function Profile(){
  const tokenList = require('./tokenListCarbonCredits');
  const abiList = require('./abiListCarbonCredits');
  const tokenAddress = require('./tokenListSBT');;
  const abi = require('./abiGPC');
  const connectedAddress = sessionStorage.getItem('connectedAddress2');
  // const isConnected = Boolean(connectedAddress);
  const [idlist, setIdlist] = useState([]);
  const [userInformation, setUserInformation] = useState({});
  const { t } = useTranslation();
  const [isBalance, setIsBalance] = useState(null);
  // const [tokenBalance, setTokenBalance] = useState(null);
  // const [owner, setOwner] = useState('1');
  const [sbtBalance, setSbtBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const chain = sessionStorage.getItem('chain');

  const buttonRef1 = useRef();
  const buttonRef2 = useRef();
  const contRef1 = useRef();
  const contRef2 = useRef();

  useEffect(() => {
    async function BalanceCheck() {
      let cont = 0;
      for (let i = 0; i < tokenList.length; i++) {
        const tokenAddress2 = tokenList[i];
        const abi2 = abiList[i];
        if (window.ethereum) {
          let provider;
          if (chain === "80002"){
            provider = new ethers.providers.Web3Provider(window.ethereum);
          } else {
            provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
            }
          // const signer = provider.getSigner();
          const contract = new ethers.Contract(
            tokenAddress2,
            abi2,
            provider
          );
          try {
            //Variables
            const tokenBalance = await contract.balanceOf(connectedAddress, 0);
            // setTokenBalance(tokenBalance);
            if (tokenBalance >= 1) {
            cont = cont + 1;
            setIsBalance(true);
            setIsLoading(false);
            break;
            }
          } catch (err) {
            // console.log("error: ", err);
            // setIsBalance(false);
            // setIsLoading(false);
          }
        }
      }
      if (cont === 0) {
      setIsBalance(false);
      setIsLoading(false);
      }
    }
    BalanceCheck();
  },[]);
  

  useEffect (() => {
    async function ProfileCheck () {
      let cont2 = 0;
        if (window.ethereum) {
          let provider;
          if (chain === "80002"){
            provider = new ethers.providers.Web3Provider(window.ethereum);
          } else {
            provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
            }
            // const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                provider
            );
            try {
                //Variables
                const id = await contract._tokenIdCounter();
                const idnum = id.toNumber();
                console.log(idnum);
                // const idlist = Array.from({ length: idnum }, (_, i) => i);
                const idlist = new Array(idnum).fill(null);
                setIdlist(idlist);
                for (let i = 0; i <= idnum; i++) {
                const owner = await contract.ownerOf(i);
                // setOwner(owner);
                if (owner.toLowerCase() === connectedAddress.toLowerCase()){
                  cont2 = cont2 + 1;
                  setSbtBalance(true);
                  setIsLoading(false);
                  console.log(cont2);
                  break;
                }}
                // console.log('Certificate contract read');
            } catch (err) {
                // console.log("errroe: ", err)
                // setSbtBalance(false);
                // setIsLoading(false);
            }
        }
        if (cont2 === 0 ){
          setSbtBalance(false);
          setIsLoading(false);
          console.log(cont2);
          }
        console.log(sbtBalance);
    }
    ProfileCheck();
  },[]);

  useEffect(() => {
    // Fetch user information based on the connected address
    
    // Create a new Headers object
    const headers = new Headers();
    headers.append("Origin", "https://app.greenpls.com");
    
    fetch(`https://api.greenpls.com/api.php/getUserByAddress?address=${connectedAddress}`, {
      method: 'GET',
      headers: headers // Pass the Headers object in the request options
    })
      .then((res) => res.json())
      .then((data) => {
        // Update the user information state with the retrieved data
        setUserInformation(data);
      });
      }, [connectedAddress]);

  const changeForm = () => {
    buttonRef1.current.classList.toggle('radio-inputs-selected');
    buttonRef2.current.classList.toggle('radio-inputs-selected');
    contRef1.current.classList.toggle('content_act');
    contRef2.current.classList.toggle('content_act');
  };

  function getTitle() {
    if (userInformation && userInformation.name && userInformation.email) {
      return `${userInformation.name} - ${userInformation.email}`;
    }
    else { return 'User not registered'}
  }

    return (
      
      <div className="body-top">
        <NavBar />
          <div className="App-content">
          {isLoading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
      ) : (
        <>
            <div className="profile_top"> 
              <h3 className="profile_title">{getTitle()} </h3>
              <p className="profile_category_title">{connectedAddress}</p>
              {/* <div className="profile_content">
                {/* <div className="profile_social-description">
                  <h2>3</h2>
                  <p>tCO2</p>
                </div>
                <div className="profile_social-description">
                  <h2>2</h2>
                  <p>Projects</p>
                </div>
                <div className="profile_social-description">
                  <h2>2</h2>
                  <p>Categories</p>
                </div>
                <div className="profile_social-description">
                  <h2>2</h2>
                  <p>Countries</p>
                </div>
              </div> */}
          </div>
          <div className="radio-inputs">
          { <React.Fragment>
            <label className="radio">
             <input type="radio" name="radio"  onClick={changeForm} className="radio-inputs-selected"></input>
             <span ref={buttonRef1} className="radio-inputs-selected name">{t("cc")}</span>
            </label>
            <label className="radio">
             <input type="radio" name="radio" /*checked = ""*/  onClick={changeForm}></input>
             <span ref={buttonRef2} className="name">{t('certificados')}</span>
            </label>
            </React.Fragment>}
          </div>
          <form /*onSubmit={(e) => {handleSubmit(e);}}*/>
          <div ref={contRef1} className="content content_act">
            {/* <h2>CERTIFIED OFFSETS</h2> */}
            {isBalance === true && (
            <>
            <div className="nft-list gap-10">
            {tokenList.map((token, index) => (<ProfileCarbonCredits key = {index} index = {index}/>))}
            </div>
              <div className='nft-info-advertencia'>
              <img src={adv} alt="adv"></img>
              <h3 className='profile-warning'>{t("profile-adv-1")}</h3>
              <img src={adv} alt="adv"></img>
            </div>
            <div className="center-adv" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p className="profile_category_title">{t("profile-adv-2")}</p>
            </div>
            </>
            )}
            {isBalance === false && (
            <p className="intro-text" style={{ textAlign: 'center', maxWidth: '900px', marginTop:'100px'}}>{t("profile-text-cc")}</p>
            )}
          </div>
          </form>
          <form /*onSubmit={(e) => {handleCompanySubmit(e);}}*/> 
          <div ref={contRef2} className="content">
            {/* <h2>CARBON CREDITS</h2> */}
            {sbtBalance === true && (
            <>
            <div className="nft-list gap-10">
            {idlist.map((id,index) => (<ProfileSBTs key = {index} index = {index}/>))}
            </div>
            <div className='nft-info-advertencia'>
            <img src={adv} alt="adv"></img>
            <h3 className='profile-warning'>{t("profile-adv-3")}</h3>
            <img src={adv} alt="adv"></img>
          </div>
          <div className="center-adv" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
           <p className="profile_category_title">{t("profile-adv-4")}</p>
          </div>
            </>
            )}
            {sbtBalance === false && (
            <p className="intro-text" style={{ textAlign: 'center', maxWidth: '900px', marginTop:'100px'}}>{t("profile-text-sbts")}</p>
            )}
            </div>
          </form>
          </>
          )}
        </div> 
      </div>
    );
  }
  
  export default Profile;
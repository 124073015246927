import React, { useEffect, useState } from "react";
import axios from "axios";
import "./transactiontable.css";
import tokenlist from './tokenListCarbonCredits'; 

const TransactionTable =  ({ selectedindex }) => {
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      const apiKey = process.env.POLYGONSCAN_API_KEY;
      const endpointUrl = "https://api-amoy.polygonscan.com/api";
      const module = "account";
      const action = "txlist";
      const address = tokenlist[selectedindex];
      const startblock = 0;
      const endblock = 99999999;
      const sort = "desc";

      const url = `${endpointUrl}?module=${module}&action=${action}&address=${address}&startblock=${startblock}&endblock=${endblock}&sort=${sort}&apikey=${apiKey}`;

      try {
        const response = await axios.get(url);
        const { result } = response.data;
        setTransactionData(result);
      } catch (error) {
        // console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [selectedindex]);

   
    // Function to truncate text
    const truncateText = (text, maxLength) => {
      if (text.length <= maxLength) {
        return text;
      }
      const truncatedText = text.substr(0, maxLength / 2) + "..." + text.substr(-maxLength / 2);
      return truncatedText;
    };

// Function to convert value to MATIC
const convertToMATIC = (value) => {
  const decimalFactor = 10 ** 18;
  const valueInMATIC = parseFloat(value) / decimalFactor;
  return valueInMATIC.toFixed(3); // Adjust the decimal places as needed
};

const convertUnixTimestamp = (unixTimestamp) => {
  const currentTime = Math.floor(Date.now() / 1000); // Current timestamp in seconds
  const timeDifference = currentTime - unixTimestamp;

  const minutesAgo = Math.floor(timeDifference / 60);
  const hoursAgo = Math.floor(timeDifference / 3600);
  const daysAgo = Math.floor(timeDifference / 86400);

  if (daysAgo >= 1) {
    const remainingHours = hoursAgo - daysAgo * 24;
    const formattedTime = `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ${remainingHours} ${remainingHours === 1 ? 'hr' : 'hrs'}`;
    return `${formattedTime} ago`;
  } else if (hoursAgo >= 1) {
    const remainingMinutes = minutesAgo - hoursAgo * 60;
    const formattedTime = `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ${remainingMinutes} ${remainingMinutes === 1 ? 'min' : 'mins'}`;
    return `${formattedTime} ago`;
  } else {
    return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
  }
};

const getMethod = (input) => {
  const mintId = "0xa0712d68";
  const burnId = "0x7641e6f3";
  const createId = "0x60806040";
  const infoUpdate = "0x02fe5305";
  const setmintWindow = "0x18b4e2bb";
  const updateSupply = "0x211bf9df";
  const setgenuineState = "0x93fde0a8";
  const withdrawfundStorage = "0x62c2d682";
  const SetKyc =  "0xd6dd4450";

  if (input === mintId) {
    return "Buy";
  } else if (input === burnId) {
    return "Retire";
  } else if (input === createId) {
    return "Contract creation";
  } else if (input === infoUpdate) {
    return "Credit Info Updated";
  } else if (input === setmintWindow){
    return "Buy Window Set";
  } else if (input === updateSupply){
    return "Supply Update";
  } else if (input === setgenuineState){
    return "Genuine State Set";
  } else if (input === withdrawfundStorage){
    return "Fund Storage Withdraw";
  } else if (input === SetKyc){
    return "KYC Set";
  } else {
    return "";
  }
};

    return (
      <div className="transaction-table-container0">
        <div className="table-wrapper">
        <table className="transaction-table2">
          <thead>
            <tr>
              <th>Transaction Hash</th>
              <th>Block Number</th>
              <th>Method</th>
              <th>Age</th>
              <th>From</th>
              <th>To</th>
              <th>Value</th>
            </tr>
          </thead>
          </table>
          <table className="transaction-table">
          <tbody>
            {transactionData.map((transaction) => (
              <tr key={transaction.hash}>
                <td>{truncateText(transaction.hash, 12)}</td>
                <td>{transaction.blockNumber}</td>
                <td>{getMethod(transaction.methodId)}</td>
                <td>{convertUnixTimestamp(transaction.timeStamp)}</td>
                <td>{truncateText(transaction.from, 12)}</td>
                <td>{truncateText(transaction.to, 12)}</td>
                <td>{convertToMATIC(transaction.value)} MATIC</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    );
};

export default TransactionTable;
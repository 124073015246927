import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import TokenInfo from './TokenInfo';
import FinanceInfo from './FinanceInfo';
import NavBar from './NavBar';
import Matic from './img/polygon-matic-logo.png';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';


function CarbonCredits() {
  const financeList = require('./financeList');
  const fundStorageAbi = require('./FundStorageAbi');
  const fundStorageAddress = process.env.REACT_APP_FUND_STORAGE;
  const GPcAddress = process.env.REACT_APP_GPC_ADDRESS;
  const GPcAbi = require('./abiGPC');
  const [balanceInMatic, setBalanceinMatic]= useState(null);
  const [tCO2, settCO2] = useState(null);
  const [Offset, setOffset] = useState(null);
  const { t } = useTranslation();
  const chain = sessionStorage.getItem('chain');
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [checkedCheckboxes1, setCheckedCheckboxes1] = useState([]);
  const [updatedTokenList, setUpdatedTokenList] = useState([0,1,2,3]);
  
  
  useEffect (() => {
    async function ReadFundStorage () {
      let provider;
      if (chain === "80002"){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
        }
            const contract = new ethers.Contract(
                fundStorageAddress,
                fundStorageAbi,
                provider
            );
            try {
                //Variables
                const balance = await provider.getBalance(fundStorageAddress);
                const balanceInMatic = ethers.utils.formatEther(balance);
                const balanceRounded = balanceInMatic.substring(0, balanceInMatic.indexOf('.')+5);
                const tCO2 = await contract.tCO2();
                settCO2(tCO2);
                setBalanceinMatic(balanceRounded);
  
            } catch (err) {
                // console.log("errroe: ", err)
            }
        
    }
    async function ReadGPC () {
      let provider;
      if (chain === "80002"){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
        }
      const contract = new ethers.Contract(
          GPcAddress,
          GPcAbi,
          provider
      );
      try {
          //Variables
          const Offset = await contract.offsetcounter();
          setOffset(Offset);
      } catch (err) {
          // console.log("error: ", err)
      }
  
  }

    ReadGPC();
    ReadFundStorage();

},[]);

const handleCheckboxClick = (value) => {
  const updatedCheckboxes = [...checkedCheckboxes];

  if (updatedCheckboxes.includes(value)) {
    const index = updatedCheckboxes.indexOf(value);
    updatedCheckboxes.splice(index, 1);
  } else {
    updatedCheckboxes.push(value);
  }
  // console.log(value);
  setCheckedCheckboxes(updatedCheckboxes);
  // console.log(checkedCheckboxes);
  // console.log(updatedCheckboxes);
  filterProducts(updatedCheckboxes);
};

const handleCheckboxClick1 = (value) => {
  const updatedCheckboxes1 = [...checkedCheckboxes1];

  if (updatedCheckboxes1.includes(value)) {
    const index = updatedCheckboxes1.indexOf(value);
    updatedCheckboxes1.splice(index, 1);
  } else {
    updatedCheckboxes1.push(value);
  }
  console.log(value);
  setCheckedCheckboxes1(updatedCheckboxes1);
  // console.log(checkedCheckboxes1);
  // console.log('updatedcheck',updatedCheckboxes1);
  newTokenList(updatedCheckboxes1);
};

const newTokenList = (updatedCheckboxes1) => {
  // const tokenList1 = [null,null,null,null];
  const tokenList1 = [];
  if (updatedCheckboxes1.length > 0) {
    const filteredProducts1 = document.querySelectorAll(`div[data-group="financiando"]`);
    filteredProducts1.forEach((product3) => {
      product3.style.display = 'none';
    });
    updatedCheckboxes1.forEach((value) => {
      if (value == 1){
        tokenList1.push(3);
        tokenList1.push(value);
      } else if (value == 4){
        const filteredProducts1 = document.querySelectorAll(`div[data-group="financiando"]`);
        filteredProducts1.forEach((product3) => {
          product3.style.display = 'block';
        });
      } else {
        tokenList1.push(value);
      }
    });
    setUpdatedTokenList(tokenList1);
  } else {
    setUpdatedTokenList([0,1,2,3]);
    const filteredProducts1 = document.querySelectorAll(`div[data-group="financiando"]`);
        filteredProducts1.forEach((product3) => {
          product3.style.display = 'block';
        });
  }
   console.log('tokenlist1', tokenList1);
};

const filterProducts = (updatedCheckboxes) => {
  const allProducts = document.querySelectorAll('.products > div');

  allProducts.forEach((product) => {
    // console.log('product', product);
    product.style.display = 'none';
  });

  if (updatedCheckboxes.length > 0) {
    // console.log('dentro');
    updatedCheckboxes.forEach((value) => {
      // console.log('Filtering by:', value);
      const filteredProducts = document.querySelectorAll(`div[data-group="${value}"]`);
      // console.log('filtro',filteredProducts);
      filteredProducts.forEach((product) => {
        // console.log('product2', product);
        product.style.display = 'block';
      });
    });
  } else {
    allProducts.forEach((product) => {
      product.style.display = 'block';
    });
  }
};

  return (
      <div className='body-top'>
        <NavBar/>
        <div className="App-content">
          <div className='flex-container2'>
            <div className='left-section2'>
              <div><h2 className='title'>{t('titulo-cc')}</h2></div>
              <div><p className='intro-text'>{t("intro-text-cc")}</p></div>
              {/* <div><p>{t('Final-cc')}</p></div> */}
            </div>
            <div className='right-section2'>
              <div className='carboncredits_main-live'>
                <div className='carboncredits_main-live-box'>
                  <p className='carboncredits_main-live-title'>{t('dato3-cc')}</p>
                  <div className='carboncredits_main-live-co2'>
                    <p>{tCO2 ? `${tCO2} tCO₂ ` : 'Loading...'}</p>
                  </div>
                </div>
                <div className='carboncredits_main-live-box'>
                  <p className='carboncredits_main-live-title'>{t('dato1-cc')}</p>
                  <div className='carboncredits_main-live-co2'>
                    <p>{Offset ? `${Offset} tCO₂ ` : 'Loading...'}</p>
                  </div>
                </div>
                <div className='carboncredits_main-live-box'>
                  <p className='carboncredits_main-live-title'>{t('dato2-cc')}</p>
                  <div className='carboncredits_main-live-co2'>
                    <img className='carboncredits_main-live-matic' src={Matic} alt="Matic"/>
                    <div><p>{balanceInMatic ? `${balanceInMatic}` : 'Loading...'}</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className='Marketplace'>
                <div className='Markeplace_filtro'>
                  {/* <h2>Filtro</h2> */}
                  <div className='markeplace_filtro-inputs' style={{marginTop: '-20px'}}>
                    <div><p className='intro-text' style={{textAlign: 'left'}}>Estado</p></div> 
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value="disponible" onChange={() => handleCheckboxClick('disponible')} class="cyberpunk-checkbox"/>{t('filtro-disponible')}</label>
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value="financiando" onChange={() => handleCheckboxClick('financiando')} class="cyberpunk-checkbox"/>{t('filtro-financiando')}</label>
                  </div> 
                  <div className='markeplace_filtro-inputs'>
                    <div><p className='intro-text' style={{textAlign: 'left'}}>Tipo de Proyecto</p></div> 
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value = '2' onChange={() => handleCheckboxClick1('2')} class="cyberpunk-checkbox"/>{t('filtro-reforestación')}</label>
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value = '1' onChange={() => handleCheckboxClick1('1')} class="cyberpunk-checkbox"/>{t('filtro-renovables')}</label>
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value = '0' onChange={() => handleCheckboxClick1('0')} class="cyberpunk-checkbox"/>{t('filtro-biooil')}</label>
                    <label class="cyberpunk-checkbox-label">
                    <input type="checkbox" value = '4' onChange={() => handleCheckboxClick1('4')} class="cyberpunk-checkbox"/>{t('filtro-ganadería')}</label>
                  </div>    
                </div> 
                <div className='Marketplace_contenido products'> 
                  <div data-group="disponible">
                    {/* <p className='intro-text' style={{textAlign: 'left'}}>Disponibles</p>  */}
                    <div className="nft-list gap-10" style={{justifyContent: 'flex-start'}}>
                      {updatedTokenList.map((value, index) => (<TokenInfo index = {index} value = {value}/>))}
                      {/* {financeList.map((value, index) => (<FinanceInfo index = {index} value = {value}/>))} */}
                    </div>
                  </div>
                  <div data-group="financiando">
                    <div className="nft-list gap-10" style={{justifyContent: 'flex-start'}}>
                      {financeList.map((value, index) => (<FinanceInfo index = {index} value = {value}/>))}
                    </div> 
                 </div>
              </div>
          </div>  
            <ToastContainer position="top-left"   style={{ marginTop: '130px' }}/>
        </div>           
        </div> 
  );
}

export default CarbonCredits;
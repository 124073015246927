import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import image2 from './img/EolicaEspana1.png';
import image4 from './img/SolarSpain1.png';
import image3 from './img/WorldReforestation1.png';
import image1 from './img/BioOil1.png';
  
const TokenInfoCarbonCredits = ({index}) => {
    const tokenList = require('./tokenListCarbonCredits');
    const abiList = require('./abiListCarbonCredits');
    const CIDlist = require('./CIDlist'); 
    const addressconn = sessionStorage.getItem('connectedAddress2');
    const abiCertificate = require('./abiGPC');
    const certificateAddress = process.env.REACT_APP_GPC_ADDRESS;
    // const certificateAddress = process.env.REACT_APP_CERTIFICATE_ADDRESS;
    const tokenAddress = tokenList[index];
    const abi = abiList[index];
    const [name, setName] = useState(null);
    const [isGenuine, setIsGenuine] = useState(true);
    const [balance, setBalance] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // const [sbtUri, setSbtUri] = useState();
    const [offsetAmount, setoffsetAmount] = useState(1);
    const [ID, setID] = useState(null);
    const { t } = useTranslation();
    const chain = sessionStorage.getItem('chain');
    const images = [image1, image2, image3, image4];
    const image = images[index];
    
    useEffect (() => {
        HandleInfo();
        HandleID();
        async function fetchTokenMetadataFromIPFS() {
            try {
            const metadataURL = `https://gateway.pinata.cloud/ipfs/${CIDlist[index]}`;
            const response = await fetch(metadataURL);
            const metadata = await response.json();
            const imageUrl = metadata.image;
            const tokenName = metadata.name;
            const finalImageUrl = imageUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
            setName(tokenName);
            console.log(certificateAddress);
            console.log(abiCertificate);
            // console.log('IPFS data loaded');
            } catch (error) {
              // console.log('Error fetching token metadata from IPFS:', error);
              try {
                const metadataURL = `https://ipfs.io/ipfs/${CIDlist[index]}`;
                const response = await fetch(metadataURL);
                const metadata = await response.json();
                const imageUrl = metadata.image;
                const finalImageUrl = imageUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
                // console.log(finalImageUrl);
                const tokenName = metadata.name;
                  setName(tokenName);
                  console.log(certificateAddress);
                  console.log(abiCertificate);
                  // console.log('IPFS data loaded');
              } catch (error) {
                // console.log('Error fetching token metadata from Cloudflare(IPFS):', error);
              }
            }
            
        }
        fetchTokenMetadataFromIPFS();
    },[]);

    async function HandleInfo () {
        if (window.ethereum) {
          let provider;
          if (chain === "80002"){
            provider = new ethers.providers.Web3Provider(window.ethereum);
          } else {
            provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
            }
            // const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                provider
            );
            try {
                //Variables
                const isGenuine = await contract.isGenuine();
                const addressBalance = await contract.balanceOf(addressconn,0);
                setIsGenuine(isGenuine);
                setBalance(addressBalance);
                // console.log('Blockchain data loaded');

            } catch (err) {
                // console.log("error: ", err)
            }
        }
    }

    async function HandleID () {
      if (window.ethereum) {
        let provider;
        if (chain === "80002"){
          provider = new ethers.providers.Web3Provider(window.ethereum);
        } else {
          provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
          }
          // const signer = provider.getSigner();
          const contract = new ethers.Contract(
              certificateAddress,
              abiCertificate,
              provider
          );
          try {
              //Variables
              const id = await contract._tokenIdCounter();
              const decimalId = id.toNumber(); // Convert BigNumber to decimal
              setID(decimalId);
              console.log(decimalId);

          } catch (err) {
              // console.log("error: ", err)
          }
      }
  }

      const getIsGenuine = () => {
        if (isGenuine === true) {
          return (
        <div className='mint-window'>
            <div className='green-dot'></div>
            <p className="openWindow-text">Genuine</p>
        </div>
        );
        } else {
            return (
                <div className='mint-window'>
                    <div className='red-dot'></div>
                    <p className="closedWindow-text">Fraudulent</p>
                </div>
            );
        }
      }    

      async function handleBurn() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                signer
            );
            try {
                setButtonDisabled(true);
                setTimeout(() => {
                  setButtonDisabled(false);
                }, 4000);
                const burn = `${t('confirmTransactionMessage', { offsetAmount })}`;
                toast.info(burn);
                // Await the generateJSON function and get the sbtUri value
                const generatedSbtUri = await generateJSON();
                const response = await contract.burn(offsetAmount,generatedSbtUri);
                toast.info(t("burn-confirmation"),{
                  autoClose: false,
                });
                await provider.waitForTransaction(response.hash);
                toast.success(t("certificate-success"));
                await HandleInfo();
                toast.info(t("certificate-click"),{
                  autoClose: false,
                });
                // console.log('response: ', response);

            } catch (error) {
                // console.log('error', error);
                const message = error.reason.substring(0,100);
                toast.error (message);
            }
        }
    }

      const handleDecrement = () => {
        if (offsetAmount <= 1) return;
        setoffsetAmount(offsetAmount - 1);
    };
    
    const handleIncrement = () => {
        if (offsetAmount >= balance) return;
        setoffsetAmount(offsetAmount + 1);
    };

    async function generateJSON() {
        // Create a JavaScript object representing the data
        const today = new Date().toLocaleDateString();
        const data = {
          "name": "GreenPulseCertificate",
          "image": "ipfs://QmcwPhGVDGYFuK4m9hrN1q2rn9tT7pz3EoSnjiBp8fozs6",
          "description":`"Certificate representing ${offsetAmount} tonnes of CO₂ removed or avoided from the atmosphere on ${today}"`,
          "attributes": [
            {
              "trait_type": "Offset Amount",
              "value": `${offsetAmount} tCO₂`
            },
            {
                "trait_type": "Project",
                "value": `${tokenAddress}`
            },
            {
              "trait_type": "Date of Offset",
              "value": today
            },
            {
              "trait_type":"ID",
              "value":`${ID}`
            }
          ]
        };
      
        // Convert the object to a JSON string
        const jsonString = JSON.stringify(data);
      
        // Upload the JSON file to Pinata using their API
        const url = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'pinata_api_key': 'b868163f069e519c8491',
            'pinata_secret_api_key': '8006ab2f882d1a2927ee77ee478d5f37a8ebf844ae76114a457d739bccef7079'
          },
          body: jsonString
        };
        const response = await fetch(url, options);
        const pinataResponse = await response.json();
        const sbtUri = `ipfs://${pinataResponse.IpfsHash}`;
        // setSbtUri(sbtUri);
        // console.log(sbtUri);
        return sbtUri;
      }

      const ChangeChain = () => {
        toast.info(t("mint-chain"),{
          autoClose: false,
        });
      };

        return (
        <div>
            <div className='nft-state'>
                <div>{getIsGenuine()}</div>
                <span className='quantity'>{`${balance}`}</span>
                <div className='nft'>
                    { isGenuine ? (
                        <img className='nft-img' src={image} alt="TokenImage"/>
                        ) : (
                            <div>
                                <img className='nft-img-fraud' src={image} alt="TokenImage"/>
                            </div>
                            )}
                    <div className='nft-info'>
                        <p>{name}</p>
                        <div className='nft-info-bottom'>
                        {isGenuine ? (
                          <div className="nft_burn-box">
                            <div className="input-number">
                              <button id="decrement" onClick={handleDecrement}>-</button>
                              <input
                                type="number"
                                value={offsetAmount}
                                onChange={(e) => setoffsetAmount(parseInt(e.target.value))}
                              />
                              <button id="increment" onClick={handleIncrement}>+</button>
                            </div>
                            {parseInt(chain, 10) === 80002 ? (
                              <button className="nft_block-info-buy" onClick={handleBurn} disabled={buttonDisabled}>
                                {t("quemar")}
                              </button>
                            ) : (
                              <button className="nft_block-info-buy" onClick={ChangeChain}>
                                {t("quemar")}
                              </button>
                            )}
                          </div>
                        ) : (
                          <div>
                            <p>Claim your compensation</p>
                          </div>
                        )}
                        </div>
                    </div>  
                    </div>   
                </div>
        </div>
            
        );
};

export default TokenInfoCarbonCredits;
import NavBar from "./NavBar";
import OffsetAutoGreen from './img/Auto-Offset-Green.svg';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function AutoOffset() {
  const { t } = useTranslation();

  return (
    <div className="body-top">
      <NavBar />
      <div className="App-content">
        <div className="profile_top">
            <h2 className="title">{t("title-autooffset")}</h2>
            <p className="intro-text">{t("subtitle-autooffset")}</p>
          </div>
          <div className="columns">
          <div className="column-left">
                    <div className="text-auto">
                        <p>{t("text-autooffset")}</p>
                    </div>
                    <div id="button-auto" className="button-auto">
                      <a href="https://www.greenpls.com" target="_blank" rel="noopener noreferrer">
                        <button className="request-button">
                          {t("button-autooffset")} 
                          <svg className="arrow" id= "arrow" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289L12.5858 6H1C0.447716 6 -2.41411e-08 5.55228 0 5C2.41411e-08 4.44772 0.447716 4 1 4H12.5858L10.2929 1.70711C9.90237 1.31658 9.90237 0.683418 10.2929 0.292893Z" fill="#FFFFFF80"/>
                          </svg>
                        </button>
                      </a>
                    </div>
                    </div>
                    <div className="column-right">
                        <div className="dashboard-background">
                        <img src={OffsetAutoGreen} alt=""></img>
                        </div>
                    </div>
                </div>
        <ToastContainer position="top-left" style={{ marginTop: '130px' }} />
      </div>
      </div>
  );
}

export default AutoOffset;
import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import {
    Link,
  } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import Certificate from './img/GPC.jpg';
  
const TokenSBTProfile = (props) => {
    const id = props.index;
    const imageSbt = 'ipfs://QmcwPhGVDGYFuK4m9hrN1q2rn9tT7pz3EoSnjiBp8fozs6';
    const name = 'GreenplsCertificate'
    const newTo = {
        pathname:"/InfoSBTs/"+ id
    }
    const tokenAddress = require('./tokenListSBT');;
    const abi = require('./abiGPC');;
    const [image, setImage] = useState(null);
    const [amount, setAmount] = useState(null);
    const [date, setDate] = useState(null);
    // const { t } = useTranslation();
    const chain = sessionStorage.getItem('chain');
    
    useEffect (() => {
        async function HandleInfo () {
            if (window.ethereum) {
                let provider;
                if (chain === "80002"){
                  provider = new ethers.providers.Web3Provider(window.ethereum);
                } else {
                  provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
                  }
                // const signer = provider.getSigner();
                const contract = new ethers.Contract(
                    tokenAddress,
                    abi,
                    provider
                );
                try {
                    //Variables  
                    const tokenMetadata = await contract.tokenURI(id);
                    const url = tokenMetadata.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
                    // const url = 'https://gateway.pinata.cloud/'+ tokenMetadata;
                    const response2 = await fetch(url);
                    const json = await response2.json();
                    const JsonString = JSON.stringify(json);
                    const metadata = JSON.parse(JsonString);
                    const amount = metadata.attributes[0].value;
                    const date = metadata.attributes[2].value;
                    setAmount(amount);
                    setDate(date);
                    // console.log('Data loaded');

                } catch (err) {
                    // console.log("error: ", err);
                    const tokenMetadata = await contract.tokenURI(id);
                    const url = tokenMetadata.replace('ipfs://', 'https://ipfs.io/ipfs/');
                    // const url = 'https://cloudflare-ipfs.com/'+ tokenMetadata;
                    const response2 = await fetch(url);
                    const json = await response2.json();
                    const JsonString = JSON.stringify(json);
                    const metadata = JSON.parse(JsonString);
                    const amount = metadata.attributes[0].value;
                    const date = metadata.attributes[2].value;
                    setAmount(amount);
                    setDate(date);
                    // console.log('Data loaded');
                }
            }
        }
        HandleInfo();
        async function fetchTokenMetadataFromIPFS() {
            try {
                setImage(Certificate); 
            } catch (error) {
            //   console.log('Error fetching token metadata from IPFS:', error);
            try {
                const finalurl = imageSbt.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
                // const finalurl = 'https://cloudflare-ipfs.com/' + imageSbt;
                setImage(finalurl);

            } catch (error) {
                // console.log('Error fetching token metadata from IPFS:', error); 
                const finalurl = imageSbt.replace('ipfs://', 'https://ipfs.io/ipfs/');
                // const finalurl = 'https://gateway.pinata.cloud/' + imageSbt;
                setImage(finalurl);
            }
            }
        }
        fetchTokenMetadataFromIPFS();
    },[]);
    
        return (
        <div>
            <div className='nft-state'>
                <div className='nft'>
                <Link to={newTo}>
                        <img className='nft-img' src={image} alt="TokenImage"/>
                    <div className='nft-info'>
                        <p>{name}</p>
                        <div className='nft-info-bottom'>
                        <div className='nft-info-price-box'>
                                <div className='nft-info-price'>
                                <p className='nft-info-price-text' style={{ marginLeft: '5px'}}>{date ? `  ${date}` : 'Not available'} </p>
                                </div>
                            <div className='nft-info-price'>
                                <p className='nft-info-price-text' style={{ marginRight: '5px'}}>{amount ? `  ${amount}` : 'Not available'} </p>
                            </div>
                            </div> 
                        </div>
                    </div>      
                    </Link>
                </div>
                </div>
        </div>  
        );
};

export default TokenSBTProfile;
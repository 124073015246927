import { useEffect, useState } from "react";
import { React } from 'react';
import { ethers, BigNumber } from 'ethers';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './NavBar'; 
import Matic from './img/polygon-matic-logo.png';
import TransactionTable from "./TransactionTable";
import "./transactiontable.css";
import { useTranslation } from 'react-i18next';
import image2 from './img/EolicaEspana1.png';
import image4 from './img/SolarSpain1.png';
import image3 from './img/WorldReforestation1.png';
import image1 from './img/BioOil1.png';
import copy from './img/clipboard.svg';



const MainMint2 = () => {
    const connectedAddress = sessionStorage.getItem('connectedAddress2');
    const isConnected = Boolean(connectedAddress);
    const [mintAmount, setMintAmount] = useState(1);
    const {index} = useParams();
    const tokenList = require('./tokenListCarbonCredits');
    const abiList = require('./abiListCarbonCredits');
    const tokenAddress = tokenList[index];
    const abi = abiList[index];
    const images = [image1, image2, image3, image4];
    const image = images[index];
    const chain = sessionStorage.getItem('chain');
    // const [image, setImage] = useState(null);
    const [price, setPrice] = useState(null);
    const [tokenName, setName] = useState(null);
    const [maxSupply, setMaxSupply] = useState(null);
    const [available, setAvailable] = useState(null);
    const [description, setDescription] = useState(null);
    const [attributesMap, setAttributesMap] = useState([]);
    const [isGenuine, setIsGenuine]= useState(true);
    const [isDatabase, setIsDataBase] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const CIDlist = require('./CIDlist');
    const addressUrl = "https://amoy.polygonscan.com/address/" + tokenList[index];
    const { t } = useTranslation();

    function sliceAddress(text){
        if (text.length>40){
            const newtext = text.slice(0, 4) + '...' + text.slice(-4);
            return newtext
        }
        else {
            return text
        }
    }
    
    
    useEffect (() => {
        HandleInfo();
        async function fetchTokenMetadataFromIPFS() {
            try {
              const metadataURL = `https://gateway.pinata.cloud/ipfs/${CIDlist[index]}`;
              const response = await fetch(metadataURL);
              const metadata = await response.json();
        
            //   const imageUrl = metadata.image;
            //   const finalImageUrl = imageUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
              const attributes = metadata.attributes;
              const attributesMap = attributes.map(attribute => (
                <div className="nft_attributes_block-content-item" key={attribute.trait_type}>
                  <p className="nft_block-info-title">{attribute.trait_type}</p>
                  <p className="nft_attributes_block-content-item-subtitle">{sliceAddress(attribute.value)}</p>
                </div>
              ));
              const tokenName = metadata.name;
              const description = metadata.description;
              const desiredTrait = attributes.find((attribute) => attribute.trait_type === "CO2 Emissions Saved (tCO2)");
              setDescription(description);
              setName(tokenName);
            //   setImage(finalImageUrl);
              setAttributesMap(attributesMap);
              setMaxSupply(desiredTrait.value);
        
            //   console.log('IPFS data loaded');
            } catch (error) {
              // console.log('Error fetching token metadata from (Pinata)IPFS:', error);
              try {
                const metadataURL = `https://ipfs.io/ipfs/${CIDlist[index]}`;
                const response = await fetch(metadataURL);
                const metadata = await response.json();
                // const imageUrl = metadata.image;
                // const finalImageUrl = imageUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const attributes = metadata.attributes;
                const attributesMap = attributes.map(attribute => (
                  <div className="nft_attributes_block-content-item" key={attribute.trait_type}>
                    <p className="nft_block-info-title">{attribute.trait_type}</p>
                    <p className="nft_attributes_block-content-item-subtitle">{sliceAddress(attribute.value)}</p>
                  </div>
                ));
                const tokenName = metadata.name;
                const description = metadata.description;
                const desiredTrait = attributes.find((attribute) => attribute.trait_type === "CO2 Emissions Saved (tCO2)");
                setDescription(description);
                setName(tokenName);
                // setImage(finalImageUrl);
                setAttributesMap(attributesMap);
                setMaxSupply(desiredTrait.value);
          
                // console.log('IPFS data loaded');
              } catch (error) {
                // console.log('Error fetching token metadata from (Cloudfare)IPFS:', error);
              }
            }  
          }
        
          fetchTokenMetadataFromIPFS();
    },[]);
    
    async function HandleInfo () {
      let provider;
      if (chain === "80002"){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
        }
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                provider
            );
            try {
                //Variables
                const tokenPrice = await contract.tokenPrice();
                const tokenPriceMatic = ethers.utils.formatEther(tokenPrice);
                const totalSupply = await contract.supplyCounter();
                const maxSupply = await contract.maxSupply();
                const isGenuine = await contract.isGenuine();
                const available = maxSupply-totalSupply;
                setIsGenuine(isGenuine);
                setPrice(tokenPriceMatic);
                setAvailable(available);
                // console.log('Blockchain data loaded');

            } catch (err) {
                // console.log("error: ", err)
            }
        }

        useEffect(() => {
            // Fetch user information based on the connected address
            
            // Create a new Headers object
            const headers = new Headers();
            headers.append("Origin", "https://app.greenpls.com");
            
            fetch(`https://api.greenpls.com/api.php/checkUserByAddress?address=${connectedAddress}`, {
              method: 'GET',
              headers: headers // Pass the Headers object in the request options
            })
              .then((res) => res.json())
              .then((data) => {
            // Check if the user exists in the database
            const userExists = data.exists;
        
            // You can use 'userExists' in your code as needed
            if (userExists) {
              setIsDataBase(true);
            } else {
              setIsDataBase(false);
            }
          })
              }, [connectedAddress]);
    

    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                signer
            );
            try {
                setButtonDisabled(true);
                setTimeout(() => {
                  setButtonDisabled(false);
                }, 4000);
                const mint = `${t('confirmMintMessage', { mintAmount })}`;
                toast.info(mint);
                const price = await contract.tokenPrice();
                const response = await contract.mint(BigNumber.from(mintAmount), {
                    value: ((price * mintAmount).toString()),
                });
                toast.info(t("mint-confirmation"),{
                  autoClose: false,
                });
                await provider.waitForTransaction(response.hash);
                await HandleInfo();
                toast.success(t("mint-buy"));
            } catch (error) {
                // console.log('error', error);
                const message = error.reason.substring(20);
                if (message === 'ction'){
                    toast.error (error.reason);
                }
                else{
                    toast.error (message);
                }
                
            }
        }
    }

    async function copyAddress() {
        try {
          await navigator.clipboard.writeText(tokenAddress);
          toast.success(t("copy-succesfull"));
          /* Resolved - text copied to clipboard successfully */
        } catch (err) {
          // console.error('Failed to copy: ', err);
          /* Rejected - text failed to copy to the clipboard */
        }
    }

    function formatPrice(price, decimalPlaces) {
        const formattedPrice = (price * mintAmount).toFixed(decimalPlaces);
        return parseFloat(formattedPrice).toString(); // Remove trailing zeros
    }

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        const newValue = parseInt(mintAmount) - 1;
        setMintAmount(newValue);
    };

    const handleIncrement = () => {
        const newValue = parseInt(mintAmount) + 1;
        setMintAmount(newValue);
    };

    const ClaimCompensation = () => {
        toast.success(t("mint-compensation"));
    };

    const ConnectWallet = () => {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 4000);
        toast.info(t("mint-connect"),{
          autoClose: false,
        });
    };

    const Register = () => {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 4000);
        toast.info(t("mint-register"),{
          autoClose: false,
        });
    };

    const ChangeChain = () => {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 4000);
      toast.info(t("mint-chain"),{
        autoClose: false,
      });
    };

    return (
        <div className="body-top">
            <NavBar/>
                <div>
                <div className="App-content">
                        <div className="nft_block">
                            <div className="nft_block-info">
                                <div>
                                <div className="nft_block-info-collection">
                                    <img src={image} alt="Matic" className='nft_block-info-collection-icon' />
                                    <h1 className='nft_block-info-collection-name'>{tokenName}</h1>
                                </div>
                                { isGenuine ? (
                                <img src={image} alt="TokenImage" className = "nft_block-image"/>
                                ) : (
                                    <div>
                                        <img src={image} alt="TokenImage" className = "nft_block-image-fraud"/>
                                    </div>
                                    )}
                                </div>
                                    <div className="nft_block-info-description">
                                        <p>{description}</p>
                                    </div>
                                    <div className='nft-info-price copyaddress'>
                                        <img src={copy} onClick={copyAddress} alt="Copy" className="pointer"></img>
                                        <p className="nft_block-info-title">{t("copy-address")}</p>
                                    </div>
                            </div>
                                <div className="nft_block-info">
                                    { isGenuine ? (
                                        <div className="nft_block-info-box">
                                            <div className="nft_block-info-price">
                                                <div className="nft_block-info-price-matic">
                                                <img src={Matic} alt="Matic" />
                                                <p>{price ? formatPrice(price, 4) : 'Not available'}</p>
                                                </div>
                                            </div>
                                            <div className="input-number">
                                                <button id = "decrement" onClick={handleDecrement}>-</button>
                                                <input type="number" value={mintAmount} onChange={(e) => setMintAmount(e.target.value)}/>
                                                <button id = "increment" onClick={handleIncrement}>+</button>
                                            </div>
                                            {isConnected ? (
                                              parseInt(chain, 10) === 80002 ? (
                                                isDatabase ? (
                                                  <button className="nft_block-info-buy" onClick={handleMint}  disabled={buttonDisabled}>
                                                    {t("comprar")}
                                                  </button>
                                                ) : (
                                                  <button className="nft_block-info-buy" onClick={Register} disabled={buttonDisabled}>{t("comprar")}</button>
                                                )
                                              ) : (
                                                // Display a button when the chainId is not 80002
                                                <button className="nft_block-info-buy" onClick={ChangeChain} disabled={buttonDisabled}>{t("comprar")}</button>
                                              )
                                            ) : (
                                              <button className="nft_block-info-buy" onClick={ConnectWallet} disabled={buttonDisabled}>
                                                {t("comprar")}
                                              </button>
                                            )}
                                        </div>
                                        ) : (
                                            <div>
                                                <button className="claim-button" onClick={ClaimCompensation}>{t("compensación")}</button>
                                            </div>
                                            )}
                                            <p className="nft_block-info-quantity"> {available && maxSupply ? `${available} of ${maxSupply} available` : 'Loading available supply...'}</p>
                                            <div className="nft_block-info-list">
                                                {attributesMap}
                                            </div>   
                                            <div className="nft_block-info-address">
                                                <a className="contract-addr-button" href={addressUrl} target="_blank" rel="noreferrer">{t("contrato")}</a>
                                            </div>
                                </div>
                        </div>
                            <div className='transaction-table-container'>
                                <h3>{t("transacciones")}</h3>
                                <TransactionTable selectedindex = {index}/>
                            </div>
                <ToastContainer position="top-left"   style={{ marginTop: '130px' }}/> 
                </div>
            </div>
        </div>  
);
};

export default MainMint2;
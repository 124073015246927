import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import TokenInfoProfile from './TokenInfoProfile';


  
const ProfileCarbonCredits = ({index}) => {
    
    const tokenList = require('./tokenListCarbonCredits');
    const abiList = require('./abiListCarbonCredits');
    const tokenAddress = tokenList[index];
    const abi = abiList[index];
    const connectedAddress2 = sessionStorage.getItem('connectedAddress2');
    const [tokenBalance, setTokenBalance] = useState(null);
    const chain = sessionStorage.getItem('chain');
    
    useEffect (() => {
        async function ProfileCheck () {
            if (window.ethereum) {
                let provider;
                if (chain === "80002"){
                  provider = new ethers.providers.Web3Provider(window.ethereum);
                } else {
                  provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
                  }
                // const signer = provider.getSigner();
                const contract = new ethers.Contract(
                    tokenAddress,
                    abi,
                    provider
                );
                try {
                    //Variables
                    const tokenBalance = await contract.balanceOf(connectedAddress2,0);
                    setTokenBalance(tokenBalance);

                } catch (err) {
                    // console.log("errroe: ", err)
                }
            }
        }
        ProfileCheck();
        
    },[]);

    //Agrupar cuando hay más de uno de una colección
    if (tokenBalance >= 1){
        return (
            <div className="nft-list gap-10">
              {<TokenInfoProfile key={tokenAddress} index={index} tokenBalance={tokenBalance} />}
              {/* {<TokenInfo index = {index}/>} */}
            </div>
            );
        }
        
};

export default ProfileCarbonCredits;
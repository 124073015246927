import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import {
    Link,
  } from "react-router-dom";
import Matic from './img/polygon-matic-logo.png';
import { useTranslation } from 'react-i18next';
import image1 from './img/GAN1.png';

const TokenInfo = ({index}) => {
    
    const financeList = require('./financeList');
    const abiList = require('./abiFin');
    const CIDlist = require('./CIDlistFin'); 
    const newTo = {
        pathname:"/FinanceCarbonCredit/"+ index
    }
    const tokenAddress = financeList[index];
    const abi = abiList[index];
    const image = image1;
    const [price, setPrice] = useState(null);
    const [priceUSD, setPriceUSD] = useState(null);
    const [name, setName] = useState(null);
    const [isGenuine, setIsGenuine] = useState(true);
    const [progreso, setProgreso] = useState(true);
    const { t } = useTranslation();
    const chain = sessionStorage.getItem('chain');
 
    
    useEffect (() => {
        async function HandleInfo_chain () {
            let provider
            if (chain == "80002"){
                provider = new ethers.providers.Web3Provider(window.ethereum);
              } else {
                provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414');
              }
                const contract = new ethers.Contract(
                    tokenAddress,
                    abi,
                    provider
                );
                try {
                    //Variables
                    const tokenPrice = await contract.price();
                    const tokenPriceMatic = ethers.utils.formatEther(tokenPrice);
                    const totalBalance = await contract.totalBalance();
                    const maxSupply = await contract.maxSupply();
                    const Progreso = (totalBalance/maxSupply)*100;
                    const progresoredondeado = Progreso.toFixed(1);
                    setProgreso(progresoredondeado);
                    setPrice(tokenPriceMatic);
                    console.log('Blockchain data loaded');
                } catch (err) {
                    console.log("errroe: ", err)
                }
            
        }
        HandleInfo_chain();
        async function fetchTokenMetadataFromIPFS() {
            try {
              const metadataURL = `https://gateway.pinata.cloud/ipfs/${CIDlist[index]}`;
              const response = await fetch(metadataURL);
              const metadata = await response.json();
              const imageUrl = metadata.image;
              const finalImageUrl = imageUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
              const tokenName = metadata.name;
                setName(tokenName);
                // setImage(finalImageUrl);
            } catch (error) {
              console.log('Error fetching token metadata from Pinata(IPFS):', error);
              try {
                const metadataURL = `https://ipfs.io/ipfs/${CIDlist[index]}`;
                const response = await fetch(metadataURL);
                const metadata = await response.json();
                const imageUrl = metadata.image;
                const finalImageUrl = imageUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const tokenName = metadata.name;
                  setName(tokenName);
                //   setImage(finalImageUrl);
              } catch (error) {
                console.log('Error fetching token metadata from Cloudflare(IPFS):', error);
              }
            }   
        }
        fetchTokenMetadataFromIPFS();
    }, [tokenAddress]);  
    
        return (
        <div>
            <div className='nft-state'>
                {/* <div>{getMintWindow()}</div>
                <div>{getIsGenuine()}</div> */}
                <div className='mint-window'>
                    <div className='orange-dot'></div>
                    <p className="Financing-text">{t('filtro-financiando')}</p>
                </div>
                <div className='nft'>   
                <Link to={newTo}>
                    { isGenuine ? (
                        <img className='nft-img' src={image} alt="Token Image"/>
                        ) : (
                            <div>
                                <img className='nft-img-fraud' src={image} alt="Token Image"/>
                            </div>
                            )}
                    <div className='nft-info'>
                        {/* <p>{name}</p> */}
                        <p>Ganadería Sostenible Lacteos S.A</p>
                        <div className='nft-info-bottom'>
                            { isGenuine ? (
                            <div className='nft-info-price-box' >
                                <div className='nft-info-price'>
                                <img src={Matic} alt="Matic" className='image' />
                                <p className='nft-info-price-text'>{price ? `  ${price}` : 'Loading...'} </p>
                                </div>
                            <div className='nft-info-price-quantity'>
                            {/* <span>{supplyCounter && maxSupply ? `${supplyCounter} / ${maxSupply} ` : 'Loading...'}</span> */}
                            <span>{t('fin-progreso')}: {progreso} %</span>
                            {/* <div className="radial-progress" style={{"--value":70}} role="progressbar">25%</div> */}
                            </div>
                            </div>
                            ) : (
                            <div>
                                <p>{t('compensación')}</p>
                            </div>
                            )}
                        </div>
                    </div>      
                    </Link>
                </div>
                </div>
        </div>
            
        );
};

export default TokenInfo;
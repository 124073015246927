import React, { useState } from 'react';
import NavBar from './NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function KycRequest() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const address = sessionStorage.getItem('connectedAddress2');
  const [state, setState] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedOption === null) {
      // Display a validation message if no option is selected
      toast.info(t("kyc-select"), { toastId: 'validationToast' },{autoClose: false,});
      return; // Prevent further execution of the function
    }

        // Add CORS headers
        const headers = new Headers();
        headers.append("Origin", "https://app.greenpls.com");

    fetch('https://api.greenpls.com/api.php/registerUserDEMO', {
      method: 'POST',
      crossDomain: true,
      headers: headers,
      body: JSON.stringify({
        name,
        email,
        address,
        state,
        selectedOption,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          // Display error message from the server
          toast.error(data.error, { toastId: 'errorToast' });
        } else if (data.status === 'ok') {
          // Clear form fields
          setName('');
          setEmail('');
          // setAddress('');
          setSelectedOption(null);
          // Reset input values to placeholders
          document.getElementById('username').value = '';
          document.getElementById('email').value = '';
          document.getElementById('address').value = '';
          document.getElementById('individual').value = null;
          document.getElementById('company').value = null;
          // Display success message
          toast.success(t("kyc-succesfull"), { toastId: 'successToast' });
          //Go to Home Page
          setTimeout(() => {
            // Redirect to the home page
            window.location.href = '/'; // Replace '/' with the actual URL of your home page
          }, 2000);
        } else {
          // Display generic error message
          toast.error(t("toast-error"), { toastId: 'errorToast' });
        }
      })
      .catch((error) => {
        // console.log(error);
        // Display error message for network or API-related errors
        toast.error(t("toast-error"), { toastId: 'errorToast' });
      });
  };
  

  return (
    <div className="body-top">
      <NavBar />
      <div className='App-content'>
      <div className="body-signup">
        <div className="signup-form3">
          <div className="profile_top">
            <h2> {t("Registro")} </h2>
          </div>
          <p className='intro-text'>{t("KYC-texto")}</p>
          <div className="contenido">
          <form className='form' onSubmit={(e) => {handleSubmit(e);}}>
          {/* <div className="contenido"> */}
             <div className="content content_act">  
              <div>
                <label htmlFor="username">{t("kyc-name")}</label>
                <input type="text" id="username" name="username" placeholder="eg. Michael123"   onChange={(e)=>setName(e.target.value)} />
              </div>
              <div>
                <label htmlFor="interested">{t("kyc-option")}</label>
                <div className="signup-form-check">
                  <input
                    type="radio"
                    id="individual"
                    name="interested"
                    value="individual"
                    checked={selectedOption === 'individual'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="individual">{t("individual-kyc")}</label>

                  <input
                    type="radio"
                    id="company"
                    name="interested"
                    value="company"
                    checked={selectedOption === 'company'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="company">{t("company-kyc")}</label>
                </div>
              </div>
              <div>
                <label htmlFor="email">{t("kyc-email")}</label>
                <input type="email" id="email" name="email" placeholder="eg. Michael@mail.com"  onChange={(e)=>setEmail(e.target.value)} />
              </div>
              <div>
                <label htmlFor="address">{t("kyc-address")}</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="eg. 0x..."
                  value={sessionStorage.getItem('connectedAddress2')}
                  disabled // This disables the input field
                />
              </div> 
              <button type="submit" onClick={(e)=>setState("accepted")} >{t("kyc-button")}</button>
              
              </div>
              {/* </div> */}
              </form>  
              </div>         
              </div>
              </div>
              <ToastContainer position="top-left" style={{ marginTop: '130px'}}/> 
              </div>
            </div>
  );
  }

  export default KycRequest;
import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import "./transactiontable.css";
import { toast } from 'react-toastify';
// import { ToastContainer } from 'react-toastify';

const AuditorProfile = () => {
  // const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  // const [showPopup, setShowPopup] = useState(false)
  // const [popupContent, setPopupContent] = useState('')
  // const kycAbi = require('./KycAbi')
  // const kycAddress = process.env.REACT_APP_KYC_ADDRESS
  const connectedAddress = sessionStorage.getItem('connectedAddress');
  const isConnected = Boolean(connectedAddress);
  // const [isAuditor, setIsAuditor] = useState(null);
  const Auditor = sessionStorage.getItem('auditor');
  // const [email2, setEmail2] = useState("");


  useEffect(() => {
  // Add CORS headers
    const headers = new Headers();
    headers.append("Origin", "https://app.greenpls.com");

    fetch('https://api.greenpls.com/api.php/getPendingUsersDEMO', {
        method: 'GET',
        crossDomain: true,
        headers: headers,
    })
    .then((res) => res.json())
    .then((data) => {
    // setData(data);
    })
    .catch((error) => {
    // console.log(error);
    // Display error message for network or API-related errors
    toast.error('An error occurred. Please try again.', { toastId: 'errorToast' });
    });

    fetch('https://api.greenpls.com/api.php/getAcceptedUsersDEMO', {
      method: 'GET',
      crossDomain: true,
      headers: headers,
  })
  .then((res) => res.json())
  .then((data2) => {
  setData2(data2);
  })
  .catch((error) => {
  // console.log(error);
  // Display error message for network or API-related errors
  toast.error('An error occurred. Please try again.', { toastId: 'errorToast' });
  });
    }, [])

    // useEffect(() => {
    //   (async () => {
    //     if (window.ethereum) {
    //       const provider = new ethers.providers.Web3Provider(window.ethereum);
    //       const signer = provider.getSigner();
    //       const contract = new ethers.Contract(
    //           kycAddress,
    //           kycAbi,
    //           signer
    //       );
    //       try {
    //         const Auditor = sessionStorage.getItem('connectedAddress2');
    //         const isAuditor = await contract.callStatic.AuditorState(Auditor);
    //         setIsAuditor(isAuditor);
    //       } catch (err) {
    //         // console.error(err);
    //       }
    //     }
    //   })();
    // }, []);
    
  

//   async function handleKYCupdate(_address, _info){
//     if (window.ethereum) {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const signer = provider.getSigner();
//         const contract = new ethers.Contract(
//             kycAddress,
//             kycAbi,
//             signer
//         );
//         try {
//             const response = await contract.setBuyerCompleted(_address,_info);
//             // console.log(_info);
//             console.log('response: ', response);

//         } catch (err) {
//             console.log("errroe: ", err)
//         }
//     }
// }

// const updateUser = (id, name, email, address) => {
//     if (window.confirm(`Are you sure you want to approve ${name}`)) {
//         // Create a new Headers object
//         const headers = new Headers();
//         headers.append("Origin", "http://app.greenpls.com");
        
//         fetch(`http://api.greenpls.com/api.php/acceptUserDEMO?email=${email}`, {
//             method: 'POST',
//             headers: headers // Pass the Headers object in the request options
//         })
//         .then((res) => res.json())
//         .then((data) => {
//             if (data.success) {
//                 const databytes = '0x48656c6c6f20576f726c64000000000000000000000000000000000000000000';
//                 alert(data.message); // Display success message
//                 handleKYCupdate(address, databytes);
//             } else {
//                 alert(data.error); // Display error message
//             }
//         })
//         .catch((error) => {
//             console.error('Error:', error);
//         });
//     }
// }; 

  return (
    <div className="body-top">
      <NavBar />
      {isConnected === true && Auditor === "true" && (
      <>
      <div className='App-content'>
      {/* <div className="transaction-table-container0">
        <h2>Pending KYCs</h2>
        <table className='transaction-table3'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>State</th>
              <th>Decline</th>
              <th>Accept</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.address}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.state}</td>
                <td>
                  <button className="buttontable" onClick={() => deleteUser(item._id, item.name, item.address)}>Decline</button>
                </td>
                <td>
                  <button className="buttontable" onClick={() => updateUser(item._id, item.name, item.email, item.address)}>Accept</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      <div className="transaction-table-container0">
        <h2>Passed KYCs</h2>
        <table className='transaction-table3'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Address</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data2.map((item) => (
              <tr key={item.address}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.address}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      </>
      )}
    </div>     
  );
}

export default AuditorProfile;
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LogoGreenpls from './img/LogoGreenpls.png';
import { ethers } from 'ethers';
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { createWeb3Modal} from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import {mainnet, polygon, polygonAmoy} from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const projectId = process.env.REACT_APP_PROJECTID
const queryClient = new QueryClient()

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, polygon, polygonAmoy]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata /*, enableEmail: true*/ })


// 3. Create modal
createWeb3Modal({ 
  wagmiConfig, 
  projectId, 
  chains,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-color-mix': '#1e4b15',
    '--w3m-color-mix-strength': 10
  }
 })


const NavBar = () => {
  const { open } = useWeb3Modal();
  const [connectedAddress, setConnectedAddress] = useState(null);
  const [networkId, setNetwork] = useState(null);
  const isConnected = Boolean(sessionStorage.getItem('connectedAddress2'));
  const connectedAddress3 = sessionStorage.getItem('connectedAddress2');
  const kycAbi = require('./KycAbi');
  const kycAddress = process.env.REACT_APP_KYC_ADDRESS;
  const MaticPool = process.env.REACT_APP_MATIC_POOL;
  const MaticPoolAbi = require ('./MaticPoolAbi');
  // const [isAuditor, setIsAuditor] = useState(null);
  // const [isCustomer, setIsCustomer] = useState(null);
  const [isDatabase, setIsDataBase] = useState(null); 
  const [menu,setMenu]=useState(false);
  const navRef = useRef();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] =useState(i18n.language);
  const [isBalance,setIsBalance] = useState (null);
  const location = useLocation();
  const chain = sessionStorage.getItem('chain');
  const isKyc = sessionStorage.getItem('isKyc');
  const Auditor = sessionStorage.getItem('auditor');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    Cookies.set('language_preference', lng, { expires: 365 }); // Store the preference for 1 year
  };

  async function checkAuditor () {
    if (window.ethereum) {
      let provider;
      if (chain === "80002"){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
        }
        const contract = new ethers.Contract(
            kycAddress,
            kycAbi,
            provider
        );
        try {
            const Auditor = sessionStorage.getItem('connectedAddress2');
            const isAuditor = await contract.callStatic.AuditorState(Auditor);
            // setIsAuditor(isAuditor);
            sessionStorage.setItem('auditor', isAuditor);
            // console.log("Auditor: ", isAuditor)

        } catch (err) {
            // console.log("error: ", err)
        }
    }
  }

  async function checkCustomer () {
    if (window.ethereum) {
      let provider;
      if (chain === "80002"){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
        }
        // const signer = provider.getSigner();
        const contract = new ethers.Contract(
            kycAddress,
            kycAbi,
            provider
        );
        try {   
            const isCustomer = await contract.ReturnBuyerState(sessionStorage.getItem('connectedAddress2'))
            // setIsCustomer(isCustomer);
            // console.log(isCustomer);  
        } catch (err) {
            // console.log("error: ", err)
        }
    }
  }

  const fetchBalance = async () => {
    const addressconnected = sessionStorage.getItem('connectedAddress2');
    try {
      // Create a provider for the Matic network
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Get the balance of the user's connected address
      const balance = await provider.getBalance(addressconnected);
      // Convert the balance from Wei to MATIC
      const balanceInMATIC = ethers.utils.formatEther(balance);
      // Compare the balance with the threshold (1 MATIC) and set the boolean value accordingly
      const isBalanceLess = parseFloat(balanceInMATIC) < 0.0011;
      setIsBalance(isBalanceLess);
      // console.log('Is Less:', isBalanceLess);
    } catch (error) {
      // console.error('Error fetching user balance:', error);
    }
  };

  //Link to switch to mumbai network if connected to another network
  async function switchToMumbai (){
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x13882' }]
        });
        window.location.reload();
      } catch(error) {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x13882',
                chainName: 'Amoy',
                rpcUrls: ['https://rpc-amoy.polygon.technology/'],
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC',
                  decimals: 18
                }
              }
            ]
          })
          // window.location.reload();
        } else {
          // console.log('Metamask not detected');
        }
      }
    }
  }

  async function getMatic () {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
        // const signer = provider.getSigner();
        const contract = new ethers.Contract(
            MaticPool,
            MaticPoolAbi,
            wallet
        );
        try {
          const user = sessionStorage.getItem('connectedAddress2');
          const response = await contract.withdraw(user);
          toast.info(t("navbar-confirmation"),{
            autoClose: false,
          });
          await provider.waitForTransaction(response.hash);
          toast.success(t("navbar-matic"));
          setIsBalance(false);
      } catch (error) {
          // console.log('error', error);
          const message = error.error.reason.substring(20);
          if (message === 'ction'){
              toast.error (error.reason);
          }
          else{
              toast.error (message);
          }
        }
      }
}

  const handleAccountsChanged = (accounts) => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const address = account.slice(0, 4) + '...' + account.slice(-4);
      setConnectedAddress(address);
      sessionStorage.setItem('connectedAddress', address);
      sessionStorage.setItem('connectedAddress2', account);
      switchToMumbai();
      window.location = "/";
      // window.location.reload();
      // console.log('account', account);
    } else {
      setConnectedAddress(null);
      sessionStorage.removeItem('connectedAddress');
      sessionStorage.removeItem('connectedAddress2');
      sessionStorage.setItem('isKyc', false);
      sessionStorage.removeItem('auditor');
      window.location = "/";
      // checkCustomer();
      // fetchBalance();
      // checkAuditor();
      // window.location.reload();
              }
  };

  const handleChainChanged = (chainId) => {
    window.ethereum.request({ method: 'eth_chainId' })
    .then((chainId) => {
      if (chainId.length > 0) {
    console.log("dentro");
    const networkId = parseInt(chainId, 16);
    setNetwork(networkId);
    sessionStorage.setItem('connectedChain', networkId);
    sessionStorage.setItem('chain', networkId);
    // console.log(networkId);
    // checkCustomer();
    fetchBalance();
    // checkAuditor();
    window.location = "/";
  } else {
    console.log("desconectado")
    setConnectedAddress(null);
    // setIsKYC(null);
    setIsDataBase(null);
    setIsBalance(null);
    sessionStorage.removeItem('connectedAddress');
    sessionStorage.removeItem('connectedAddress2');
    sessionStorage.setItem('isKyc', false);
    sessionStorage.removeItem('auditor');
    window.location = "/";
  }})
// checkKYC();
// fetchBalance();
  };

  useEffect(() => {
    // Fetch user information based on the connected address
    
    // Create a new Headers object
    const headers = new Headers();
    headers.append("Origin", "https://app.greenpls.com");
    
    fetch(`https://api.greenpls.com/api.php/checkUserByAddress?address=${connectedAddress3}`, {
      method: 'GET',
      headers: headers // Pass the Headers object in the request options
    })
      .then((res) => res.json())
      .then((data) => {
    // Check if the user exists in the database
    const userExists = data.exists;

    // Now 'userExists' will be true if the user exists in the database, false otherwise
    // console.log('User exists:', userExists);

    // You can use 'userExists' in your code as needed
    if (userExists) {
      setIsDataBase(true);
      console.log(isDatabase);
      sessionStorage.setItem('isKyc', true);
    } else {
      setIsDataBase(false);
      console.log(isDatabase);
      sessionStorage.setItem('isKyc', false);
    }
  })
      }, [connectedAddress3]);

  useEffect(() => {
    if (window.ethereum && window.ethereum.selectedAddress) {
    fetchBalance();
    checkAuditor();
    checkCustomer();
    }
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.request({ method: 'eth_accounts' })
        .then((accounts) => {
          if (accounts.length > 0) {
            const account = accounts[0];
            // console.log(account);
            sessionStorage.setItem('connectedAddress2', account);
            const address = account.slice(0, 4) + '...' + account.slice(-4);
            setConnectedAddress(address);
            sessionStorage.setItem('connectedAddress', address);
          }
          else {
            // setIsKYC(null);
            setIsDataBase(null);
            setIsBalance(null);
            sessionStorage.setItem('isKyc', false);
            sessionStorage.removeItem('auditor');
          }
        })
        .catch((error) => {
          // console.error(error);
        });

      window.ethereum.request({ method: 'eth_chainId' })
        .then((chainId) => {
          const networkId = parseInt(chainId, 16);
          setNetwork(networkId);
          sessionStorage.setItem('connectedChain', networkId);
          sessionStorage.setItem('chain', networkId);
        })
        .catch((error) => {
          // console.error(error);
        });

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        if (typeof window.ethereum.removeListener === 'function') {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', handleChainChanged);
        }
      };
    }
  }, [connectedAddress, networkId]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
    setMenu(!menu);
  }

  function showMessage() {
    toast.info(t("navbar-metamask"),{
      autoClose: false,
    });
  }

  // function showMessageQR() {
  //   toast.info(t("message-qr"),{
  //     autoClose: false,
  //   });
  // }

  return (
    <>
    <WagmiConfig config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
    <header>
      <div>
      <div className='navbar_container'>
        <div className = 'Home'>
          <Link  to="/" >
            <img src={LogoGreenpls} alt="logo"/>
          </Link>
        </div>
        <nav ref={navRef} className='navbar_links responsive_nav'>
      <div className='list'>
        <Link to="/" className={location.pathname === '/' ? 'selected' : ''}>{t("marketplace")}</Link>
        <Link to="/Retire" className={location.pathname === '/Retire' ? 'selected' : ''}>{t("Offset")}</Link>
        <Link to="/AutoOffset" className={location.pathname === '/AutoOffset' ? 'selected' : ''}>{t("Automatización")}</Link>
        <Link to="/Calculator" className={location.pathname === '/Calculator' ? 'selected' : ''}>{t("Calculadora")}</Link>
        {/* <Link to="/Earn" className={location.pathname === '/Earn' ? 'selected' : ''}>{t("Earn")}</Link> */}
        {isConnected ? (
          <Link to="/Profile" className={location.pathname === '/Profile' ? 'selected' : ''}>{t("Profile")}</Link>
        ) : null}
        {Auditor === "true" && (
          <Link to="/AuditorProfile" className={location.pathname === '/AuditorProfile' ? 'selected' : ''}>Auditor</Link>
        )}
          </div>
          <div  className='list'>
              <label className="switch">
              <input type="checkbox"></input>
              <div className="slider-navbar">
                <span className={language === 'en' ? 'opt-active' : ''} onClick={() => changeLanguage("en")}>EN</span>
                <span className={language === 'es' ? 'opt-active' : ''} onClick={() => changeLanguage("es")}>ES</span>
              </div>
              </label>
          </div>
          <button className='nav-close-btn nav-btn' onClick={showNavbar}>
              <FaTimes/>
          </button>
        </nav>
        <div className='nav_right'>
          <div className="connect-button">
                {isConnected ? (
                <button className="w3-button-connected" onClick={() => open({ view: 'Account' })}>
                  <div className="green-circle"></div>
                  {connectedAddress}</button>
              ) : (
                <button className="w3-button" onClick={open}>Connect Wallet</button>
              )}
          </div>
            <button className='nav-btn' onClick={showNavbar} >
              {menu ? (
                <FaTimes/>
              ) : (
                <FaBars />
              )}             
            </button>
          </div>
        </div>
        {isConnected ? (
        <>
        {isKyc === "false" && chain === "80002" && location.pathname !== '/KycRequest' && (
          <div className="nav_message">
            <p>{t("message-register")}</p>
            <Link className="nav-link" to="/KycRequest">{t("link-register")}</Link>
          </div>
        )}
        {chain !== "80002" && (
            <div className="nav_message">
              <p>{t("message-chain")}</p>
              <a className="nav-link" onClick={switchToMumbai}>{t("link-chain")}</a>
            </div>
        )}
        {chain === "80002" && isKyc === "true" && isBalance === true && (
            <div className="nav_message">
              <p>{t("message-testnet-matic")}</p>
              <a className="nav-link" onClick={getMatic}>{t("link-testnet-matic")}</a>
            </div>
        
        )}
        {chain === "80002" && isKyc === "true" && isBalance === false && location.pathname !== '/Feedback' && (
          <div className="nav_message">
            <p>{t("message-feedback")}</p>
            <Link className="nav-link" to="/Feedback">{t("link-feedback")}</Link>
          </div>
        )}
        </>
        ) : (
            <div className="nav_message">
              <p>{t("message-connect")}</p>
              <Link className="nav-link" to="https://metamask.io/download/" target="_blank" onClick={showMessage}>{t("message-connect-link")}</Link>
            </div>
        )}
        </div>
    </header> 
    </QueryClientProvider>
    </WagmiConfig>
</>
  );
};

export default NavBar;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CarbonCredits from './CarbonCredits';
import MainMint2 from './MainMint2';
import FinanceDetails from './FinanceDetails';
import KycRequest from './KycRequest';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuditorProfile from './AuditorProfile';
import Profile from './Profile';
import Offset from './Offset';
import AutoOffset from './AutoOffset';
import InfoSBTs from './InfoSBTs';
import Feedback from './Feedback';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';
import Backend from 'i18next-http-backend';
import Calculator from './Calculator'
const root = ReactDOM.createRoot(document.getElementById('root'));

// Define a function to get the user's stored language preference or use 'en' as the default.
const getUserLanguagePreference = () => Cookies.get('language_preference') || 'es';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: false,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false,
    },
    lng: getUserLanguagePreference(), // Set the initial language based on the stored preference
  })
  .catch(error => {
    // Handle initialization error
  });

root.render(
  <React.StrictMode>
        <BrowserRouter>
        <I18nextProvider i18n={i18n}>
        <Routes>
          <Route  path="/" element={<CarbonCredits />} />
          <Route path="/MintCarbonCredit/:index" element={<MainMint2 />} />
          <Route path="/FinanceCarbonCredit/:index" element={<FinanceDetails />} />
          <Route path="/Retire" element={<Offset />} />
          <Route path="/AutoOffset" element={<AutoOffset />} />
          <Route  path="/KycRequest" element={<KycRequest />} />
          <Route  path="/AuditorProfile" element={<AuditorProfile />} />
          <Route  path="/Profile" element={<Profile />} />
          <Route  path="/InfoSBTs/:id" element={<InfoSBTs />} />
          <Route  path="/Feedback" element={<Feedback/>} />
          <Route  path="/Calculator" element={<Calculator />} />

        </Routes>
        </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useState } from 'react';
import NavBar from './NavBar';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Calculator from './img/calculator3.svg';
import Expert from './img/expert.svg';

const CalculadoraHuellaCarbono = () => {
  const [entradas, setEntradas] = useState([
    {
      tipoVehiculo: '',
      tipoCombustible: '',
      opcionCalculo: 'litros',
      litrosRecorridos: '',
      kilometrosRecorridos: '',
      huellaCarbono: 0,
      calculando: false,
      resultadoVisible: false,
    },
  ]);

  const [tablaEntradas, setTablaEntradas] = useState([]);
  const factoresEmisionUd = {
    turismose5: 2.250,
    turismose10: 2.133,
    turismose85: 0.373,
    turismose100: 0.021,
    turismosb7: 2.519,
    turismosb10: 2.443,
    turismosb20: 2.191,
    turismosb30: 1.939,
    turismosb100: 0.175,
    furgonetase5: 2.265,
    furgonetase10: 2.148,
    furgonetase85: 0.388,
    furgonetase100: 0.036,
    furgonetasb7: 2.505,
    furgonetasb10: 2.429,
    furgonetasb20: 2.177,
    furgonetasb30: 1.925,
    furgonetasb100: 0.161,
    camionese5: 2.253,
    camionese10: 2.136,
    camionese85: 0.376,
    camionese100: 0.024,
    camionesb7: 2.519,
    camionesb10: 2.443,
    camionesb20: 2.191,
    camionesb30: 1.939,
    camionesb100: 0.175,
    motocicletase5: 2.342,
    motocicletase10: 2.224,
    motocicletase85: 0.464,
    motocicletase100: 0.112,
  };

  const factoresEmisionKm = {
    turismosgasolina: 0.196,
    turismosgasoleo: 0.165,
    furgonetasgasolina: 0.262,
    furgonetasgasoleo: 0.258,
    camionesgasolina: 0.679,
    camionesgasoleo: 0.594,
    motocicletasgasolina: 0.103,
  };

  const { t } = useTranslation();
  const [calculando, setCalculando] = useState(false);
  const [resultadoVisible, setResultadoVisible] = useState(false);
  const [huellaCarbono, setHuellaCarbono] = useState(0);
  const [correoContacto, setCorreoContacto] = useState('');
  const [enviandoCorreo, setEnviandoCorreo] = useState(false);
  const [mostrarCalculadora, setMostrarCalculadora] = useState(false);
  const [seleccionadas, setSeleccionadas] = useState([]);
  const isKYC = sessionStorage.getItem('isKyc');
  console.log(isKYC);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const Register = () => {
    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 4000);
      toast.info(t("calc-register"),{
        autoClose: false,
      });
  }; 

  const handleTipoVehiculoChange = (e, index) => {
    const nuevasEntradas = [...entradas];
    nuevasEntradas[index].tipoVehiculo = e.target.value;
    setEntradas(nuevasEntradas);
  };

  const handleTipoCombustibleChange = (e, index) => {
    const nuevasEntradas = [...entradas];
    nuevasEntradas[index].tipoCombustible = e.target.value;
    setEntradas(nuevasEntradas);
  };

  const handleOpcionCalculoChange = (e, index) => {
    const nuevasEntradas = [...entradas];
    nuevasEntradas[index].opcionCalculo = e.target.value;
    nuevasEntradas[index].litrosRecorridos = ''; // Reiniciar litros
    nuevasEntradas[index].kilometrosRecorridos = ''; // Reiniciar kilómetros
    setEntradas(nuevasEntradas);
  };

  const handleLitrosRecorridosChange = (e, index) => {
    const nuevasEntradas = [...entradas];
    nuevasEntradas[index].litrosRecorridos = e.target.value;
    setEntradas(nuevasEntradas);
  };

  const handleKilometrosRecorridosChange = (e, index) => {
    const nuevasEntradas = [...entradas];
    nuevasEntradas[index].kilometrosRecorridos = e.target.value;
    setEntradas(nuevasEntradas);
  };

  // const handleCalcularClick = () => {
  //   setCalculando(true);

  //   const resultados = tablaEntradas.map((entrada) => {
  //     const factor = factoresEmision[entrada.tipoVehiculo.toLowerCase() + entrada.tipoCombustible.toLowerCase()];
  //     if (factor !== undefined) {
  //       const cantidad = entrada.opcionCalculo === 'litros' ? entrada.litrosRecorridos : entrada.kilometrosRecorridos;
  //       return parseFloat(cantidad) * factor;
  //     } else {
  //       console.error(Factor not found for ${entrada.tipoVehiculo}-${entrada.tipoCombustible} combination.);
  //       return 0;
  //     }
  //   });

  //   const totalHuellaCarbono = resultados.reduce((acc, val) => acc + val, 0);

  //   setHuellaCarbono(totalHuellaCarbono);
  //   setResultadoVisible(true);

  //   setTimeout(() => {
  //     setCalculando(false);
  //   }, 2000);
  // };

  
  const handleCalcularClick = () => {
    setCalculando(true);

    const resultados = tablaEntradas.map((entrada) => {
      if (entrada.opcionCalculo === 'litros') {
        const factor = factoresEmisionUd[entrada.tipoVehiculo.toLowerCase() + entrada.tipoCombustible.toLowerCase()];
        const cantidad = entrada.litrosRecorridos;
        return parseFloat(cantidad) * factor;
      } else if (entrada.opcionCalculo === 'kilometros') {
        const factor = factoresEmisionKm[entrada.tipoVehiculo.toLowerCase() + entrada.tipoCombustible.toLowerCase()];
        const cantidad = entrada.kilometrosRecorridos;
        return parseFloat(cantidad) * factor;
      }
      else {
        console.error(`Factor not found for ${entrada.tipoVehiculo}-${entrada.tipoCombustible} combination.`);
        return 0;
      }
    });

    const HuellaCarbono = resultados.reduce((acc, val) => acc + val, 0);
    const totalHuellaCarbono = parseFloat(HuellaCarbono.toFixed(2));


    setHuellaCarbono(totalHuellaCarbono);
    setResultadoVisible(true);

    setTimeout(() => {
      setCalculando(false);
    }, 2000);
  };

  const handleCorreoContactoChange = (e) => {
    setCorreoContacto(e.target.value);
  };

  const handleEnviarCorreoClick = () => {
  
    // Crear el objeto de datos a enviar
    const requestData = {
      email: correoContacto
    };

    // Add CORS headers
    const headers = new Headers();
    headers.append("Origin", "http://app.greenpls.com");
  
    // Realizar la llamada a la API PHP
    fetch('https://api.greenpls.com/api.php/emailcalculator', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        // Manejar la respuesta de la API
        setCorreoContacto('');
  
        if (data.success) {
          // La API respondió con éxito
          toast.success(t("EmailCalculator-succesfull"), { toastId: 'successToast' });
        } else {
          // La API respondió con un error
          toast.error(t("toast-error"), { toastId: 'errorToast' });
        }
      })
      .catch(error => {
        // Manejar errores de la llamada a la API
        toast.error(t("toast-error"), { toastId: 'errorToast' });
});
};

  const handleResetearCalculadora = () => {
    setEntradas([
      {
        tipoVehiculo: '',
        tipoCombustible: '',
        opcionCalculo: 'litros',
        litrosRecorridos: '',
        kilometrosRecorridos: '',
        huellaCarbono: 0,
        calculando: false,
        resultadoVisible: false,
      },
    ]);
  };

  const handleAgregarFila = () => {
    setTablaEntradas([...tablaEntradas, ...entradas]);
    setEntradas([
      {
        tipoVehiculo: '',
        tipoCombustible: '',
        opcionCalculo: 'litros',
        litrosRecorridos: '',
        kilometrosRecorridos: '',
        huellaCarbono: 0,
        calculando: false,
        resultadoVisible: false,
      },
    ]);
  };

  const handleSeleccionarFila = (index) => {
  const nuevasSeleccionadas = [...seleccionadas];

  if (nuevasSeleccionadas.includes(index)) {
    nuevasSeleccionadas.splice(nuevasSeleccionadas.indexOf(index), 1);
  } else {
    nuevasSeleccionadas.push(index);
  }

  setSeleccionadas(nuevasSeleccionadas);
};

const handleEliminarFilasSeleccionadas = () => {
  const nuevasEntradas = [...tablaEntradas];

  seleccionadas.sort((a, b) => b - a); // Ordenar de mayor a menor para evitar índices incorrectos

  seleccionadas.forEach((index) => {
    nuevasEntradas.splice(index, 1);
  });

  setTablaEntradas(nuevasEntradas);
  setSeleccionadas([]);
};

const closeCalculator = () => {
    setMostrarCalculadora(false);
    handleResetearCalculadora();
    setResultadoVisible(false);
    setTablaEntradas([]);
    setSeleccionadas([]);
}

  return (
    <div className="body-top">
      <NavBar />
      <div className="App-content">
        <div className="profile_top">
          <h2 >{t("calc-title")}</h2>
          <p className="intro-text">{/* Puedes añadir un texto introductorio aquí */}</p>
        </div>
        <div className="columns-calculator">
          <div className="column-left-calculator">
            <h2 className="intro-text">{t("calc-intro1")}</h2>
            <div className='ficha-calc'>
            <div className="intro-container">
            <p className="intro-text">{t("calc-text1")}</p>
            <img className="expert-image" src={Expert} alt="Expert Image"/>
            </div>
            <div className="contact-form">
              {/* <label></label> */}
              <input
                type="email"
                value={correoContacto}
                onChange={handleCorreoContactoChange}
                placeholder="TuEmail@ejemplo.com"
              />
              <button className="calculate-calculator" onClick={handleEnviarCorreoClick}>
                {enviandoCorreo ? `${t("enviando")}` : `${t("feedback-button")}`}
              </button>
            </div>
            </div>
          </div>
          <div className="column-right-calculator">
            <h2 className="intro-text">{t("calc-intro2")}</h2>
            <img src={Calculator} />
            { isKYC === "false" &&(
                <button className="calculate-calculator" onClick={Register}>
              {mostrarCalculadora ? `${t("probando")}` : `${t("probar")}`}
            </button> 
            )}
            { isKYC === "true" &&(
            <button className="calculate-calculator" onClick={() => setMostrarCalculadora(true)}>
              {mostrarCalculadora ? `${t("probando")}` : `${t("probar")}`}
            </button> 
            )}
            
            {mostrarCalculadora && (
              <div className="pop-up">
                <button className="close-button-calculator" onClick={() => closeCalculator()}>X</button>

                {entradas.map((entrada, index) => (
                  <div className="calculator-selection" key={index}>
                                        <div className="input-group">
                    <label>{t("op-calculo")}</label>
                    <select
                      value={entrada.opcionCalculo}
                      onChange={(e) => handleOpcionCalculoChange(e, index)}
                    >
                      <option value="litros">{t("litros")}</option>
                      <option value="kilometros">{t("km")}</option>
                    </select>
                    </div>
                    <div className="input-group">
                      <label>{t("tipo-veh")}</label>
                      <select
                        value={entrada.tipoVehiculo}
                        onChange={(e) => handleTipoVehiculoChange(e, index)}
                      >
                        <option value="">{t("input-veh")}</option>
                        <option value="turismos">{t("tur")}</option>
                        <option value="furgonetas">{t("fur")}</option>
                        <option value="camiones">{t("cam")}</option>
                        <option value="motocicletas">{t("mot")}</option>
                      </select>
                    </div>
                    <div className="input-group">
  <label>{t("tipo-comb")}</label>
  <select
    value={entrada.tipoCombustible}
    onChange={(e) => handleTipoCombustibleChange(e, index)}
  >
    <option value="">{t("input-comb")}</option>
    {entrada.opcionCalculo === "litros" ? (
      entrada.tipoVehiculo === "motocicletas" ? (
        // Si el tipo de vehículo es motocicletas, solo mostrar opciones de gasolina
        <>
          <option value="e5">E5 (I) - {t("gasolina")}</option>
          <option value="e10">E10 (I) - {t("gasolina")}</option>
          <option value="e85">E85 (I) - {t("gasolina")}</option>
          <option value="e100">E100 (I) - {t("gasolina")}</option>
        </>
      ) : (
        // Opciones generales para otros tipos de vehículos
        <>
          <option value="e5">E5 (I) - {t("gasolina")}</option>
          <option value="e10">E10 (I) - {t("gasolina")}</option>
          <option value="e85">E85 (I) - {t("gasolina")}</option>
          <option value="e100">E100 (I) - {t("gasolina")}</option>
          <option value="b7">B7 (I) - {t("gasóleo")}</option>
          <option value="b10">B10 (I) - {t("gasóleo")}</option>
          <option value="b20">B20 (I) - {t("gasóleo")}</option>
          <option value="b30">B30 (I) - {t("gasóleo")}</option>
          <option value="b100">B100 (I) - {t("gasóleo")}</option>
        </>
      )
    ) : (
      entrada.tipoVehiculo === "motocicletas" ? (
        // Si el tipo de vehículo es motocicletas y la opción de cálculo es en kilómetros, mostrar solo opciones de gasolina
        <>
          <option value="gasolina">{t("gasolina")}</option>
        </>
      ) : (
        // Opciones generales para otros tipos de vehículos si la opción de cálculo es en kilómetros
        <>
          <option value="gasolina">{t("gasolina")}</option>
          <option value="gasoleo">{t("gasóleo")}</option>
        </>
      )
    )}
  </select>
</div>
<div className="input-group">
                    <label>
                      {entrada.opcionCalculo === 'litros'
                        ? `${t("litros")}`
                        : `${t("km")}`}
                    </label>
                    <input
                      type="number"
                      value={
                        entrada.opcionCalculo === 'litros'
                          ? entrada.litrosRecorridos
                          : entrada.kilometrosRecorridos
                      }
                      onChange={
                        entrada.opcionCalculo === 'litros'
                          ? (e) => handleLitrosRecorridosChange(e, index)
                          : (e) => handleKilometrosRecorridosChange(e, index)
                      }
                      placeholder={
                        entrada.opcionCalculo === 'litros'
                          ? `${t("ing-litros")}`
                          : `${t("ing-km")}`
                      }
                    />
                    </div>
                    {entrada.resultadoVisible && (
                      <div className="resultado-modal">
                        <p>{t("huella")} {entrada.huellaCarbono} {t("ton")}</p>
                        <button onClick={() => handleResetearCalculadora(index)}>Volver</button>
                      </div>
                    )}
                                      </div>
                                    ))}
                                    <button className="agregar-calculator" onClick={handleAgregarFila}>{t("Agregar-fil")}</button>
                                    {tablaEntradas.length > 0 && (
  <div>
    <h3>{t("tabla")}</h3>
    <div className="tabla-container">
    <table>
      <thead>
        <tr>
          <th>{t("sel")}</th>
          <th>#</th>
          <th>{t("veh")}</th>
          <th>{t("comb")}</th>
          <th>{t("calc")}</th>
          <th>{t("lit")}</th>
          <th>{t("kilom")}</th>
        </tr>
      </thead>
      <tbody>
        {tablaEntradas.map((entrada, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                onChange={() => handleSeleccionarFila(index)}
                checked={seleccionadas.includes(index)}
              />
            </td>
            <td>{index + 1}</td>
            <td>{entrada.tipoVehiculo}</td>
            <td>{entrada.tipoCombustible}</td>
            <td>{entrada.opcionCalculo}</td>
            <td>{entrada.litrosRecorridos}</td>
            <td>{entrada.kilometrosRecorridos}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    <button className="eliminar-calculator" onClick={handleEliminarFilasSeleccionadas}>{t("eliminar")}</button>
  </div>
)}
{mostrarCalculadora && (
  <div className="calcular-huella-container">
    <button className="calculate-calculator" onClick={handleCalcularClick} disabled={calculando}>
      {calculando ? `${t("calculando")}` : `${t("calcular")}`}
    </button>

    {calculando && (
      <p></p>
    )}

    {resultadoVisible && !calculando && (
      <div className="resultado-modal">
        <p style={{fontSize: '18px'}}>{t("huella-eq")} {huellaCarbono} {t("ton")}</p>
        <button className="cerrar-calculator" onClick={() => setResultadoVisible(false)}>{t("cerrar")}</button>
      </div>
    )}
  </div>
)}
              </div>
            )}
          </div>
        </div>
        <ToastContainer position="top-left"   style={{ marginTop: '130px' }}/>
      </div>
    </div>
  );
};

export default CalculadoraHuellaCarbono;
import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import TokenSBTProfile from './TokenSBTProfile';
  
const ProfileSBTs = (props) => {
    
    const index = props.index;
    const tokenAddress = require('./tokenListSBT');
    const abi = require('./abiGPC');
    const connectedAddress2 = sessionStorage.getItem('connectedAddress2');
    const [owner, setOwner] = useState('1');
    const chain = sessionStorage.getItem('chain');
    
    useEffect (() => {
        async function ProfileCheck () {
            if (window.ethereum) {
                let provider;
                if (chain === "80002"){
                  provider = new ethers.providers.Web3Provider(window.ethereum);
                } else {
                  provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
                  }
                // const signer = provider.getSigner();
                const contract = new ethers.Contract(
                    tokenAddress,
                    abi,
                    provider
                );
                try {
                    //Variables
                    const owner = await contract.ownerOf(index);
                    setOwner(owner);
                    // console.log('Certificate owned');
                } catch (err) {
                    // console.log("error: ", err)
                }
            }
        }
        ProfileCheck();
        
    },[]);

    //Agrupar cuando hay más de uno de una colección
    if (owner.toLowerCase() === connectedAddress2.toLowerCase()){
        return (
            <div className="nft-list gap-10">
              {<TokenSBTProfile index = {index}/>}
            </div>
            );
        }
};

export default ProfileSBTs;
import { useState, useEffect } from 'react';
import OffsetCarbonCredits from './OffsetCarbonCredits';
import NavBar from "./NavBar";
// import image1 from './img/EolicaEspana1.png';
import OffsetWeb2 from './img/OffsetWeb2.png';
import ImageRetire2 from './img/CapturaRetire2.png';
import Image2 from './img/Imagen2Offset.png';
// import Image3 from './img/Imagen3Offset.png';
import BlackWhite from './img/BlackandWhite.svg';
import GPcertificate from './img/GPC.jpg';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
// import {Link} from "react-router-dom";
// import Matic from './img/polygon-matic-logo.png';
// import adv from './img/advertencia.svg';
// import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';



function Offset() {
  // const newTo = {
  //   pathname:"/"}
  const tokenList = require('./tokenListCarbonCredits');
  const abiList = require('./abiListCarbonCredits');
  const connectedAddress = sessionStorage.getItem('connectedAddress2');
  // const kycAddress = process.env.REACT_APP_KYC_ADDRESS;
  // const kycAbi = require('./KycAbi');
  const [highlightedIndex, setHighlightedIndex] = useState(1);
  // const [userInformation, setUserInformation] = useState({});
  // const [isKYC,setIsKYC] = useState(false);
  const isKYC = sessionStorage.getItem('isKyc');
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [isBalance, setIsBalance] = useState(false);
  // const [tokenBalance, setTokenBalance] = useState(null);
  const chain = sessionStorage.getItem('chain');

  
useEffect(() => {
// Fetch user information based on the connected address

// Create a new Headers object
const headers = new Headers();

headers.append("Origin", "https://app.greenpls.com");

fetch(`https://api.greenpls.com/api.php/getUserByAddress?address=${connectedAddress}`, {
  method: 'GET',
  headers: headers // Pass the Headers object in the request options
})
  .then((res) => res.json())
  .then((data) => {
    // Update the user information state with the retrieved data
    // setUserInformation(data);
    BalanceCheck();
    // checkKYC();
  });
  }, [connectedAddress]);


  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (highlightedIndex % 4) + 1;
      setHighlightedIndex(nextIndex);
    }, 4000);

    return () => clearInterval(interval);
  }, [highlightedIndex]);

  function highlightListItem(itemIndex) {
    setHighlightedIndex(itemIndex);
  }

  // function getTitle() {
  //   if (userInformation && userInformation.name && userInformation.email) {
  //     return `${userInformation.name} - ${userInformation.email}`;
  //   }
  // }
  // async function checkKYC () {
  //   if (window.ethereum) {
  //       const provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414');
  //       const signer = provider.getSigner();
  //       const contract = new ethers.Contract(
  //           kycAddress,
  //           kycAbi,
  //           provider
  //       );
  //       try {
  //           const user = sessionStorage.getItem('connectedAddress2');
  //           const isKYC = await contract.ReturnBuyerState(user);
  //           setIsKYC(isKYC);
  //           setIsLoading(false);

  //       } catch (err) {
  //           // console.log("errroe: ", err)
  //           setIsLoading(false);
  //       }
  //   }
  //   else {
  //     setIsLoading(false);
  //   }
  // }

    async function BalanceCheck() {
      for (let i = 0; i < tokenList.length; i++) {
        const tokenAddress2 = tokenList[i];
        const abi2 = abiList[i];
        if (window.ethereum) {
          let provider;
          if (chain === "80002"){
            provider = new ethers.providers.Web3Provider(window.ethereum);
          } else {
            provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
            }
          // const signer = provider.getSigner();
          const contract = new ethers.Contract(
            tokenAddress2,
            abi2,
            provider
          );
          try {
            //Variables
            const tokenBalance = await contract.balanceOf(connectedAddress, 0);
            // setTokenBalance(tokenBalance);
            if (tokenBalance >= 1) {
            setIsBalance(true);
            setIsLoading(false);
            break;
            }
          } catch (err) {
            // console.log("error: ", err);
          }
        }
      }
      setIsLoading(false);
    }

  // useEffect(() => {
  //   // Fetch user information based on the connected address
    
  //   // Create a new Headers object
  //   const headers = new Headers();
  //   headers.append("Origin", "https://app.greenpls.com");
    
  //   fetch(`https://api.greenpls.com/api.php/checkUserByAddress?address=${connectedAddress}`, {
  //     method: 'GET',
  //     headers: headers // Pass the Headers object in the request options
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //   // Check if the user exists in the database
  //   const userExists = data.exists;

  //   // Now 'userExists' will be true if the user exists in the database, false otherwise
  //   // console.log('User exists:', userExists);

  //   // You can use 'userExists' in your code as needed
  //   if (userExists) {
  //     setIsDataBase(true);
  //   } else {
  //     setIsDataBase(false);
  //   }
  // })
  //     }, [connectedAddress]);



  return (
    <div className="body-top">
      <NavBar />
      
        <div className="App-content">
          <div className="profile_top">
          <h2 className='title'>{t("offset-title")}</h2>
          {/* <h3 className="profile_title"> {getTitle()} </h3>
            <p className="profile_category_title">{connectedAddress}</p> */}
            <p className="intro-text" style={{ textAlign: 'center', maxWidth: '1050px'}}>{t("intro-text-offset")}</p>
            </div>
            {isKYC === "false" && (
        <div className="offsetdisconnected">
          <div className="offsetdisconnected-left">
            <div className="disconnected-section">
              {/* <h2>Burn your credits and recieve a verified carbon offset certificate with its digital SBT blockchain twin!</h2> */}
              <div className="steps-list">
                <ol>
                <li
                    className={`step-item ${
                      highlightedIndex === 1 ? 'selected' : ''
                    }`}
                    onClick={() => highlightListItem(1)}
                  >
                    {t("offset1")}
                  </li>
                  <li
                    className={`step-item ${
                      highlightedIndex === 2 ? 'selected' : ''
                    }`}
                    onClick={() => highlightListItem(2)}
                  >
                    {t("offset2")}
                  </li>
                  <li
                    className={`step-item ${
                      highlightedIndex === 3 ? 'selected' : ''
                    }`}
                    onClick={() => highlightListItem(3)}
                  >
                    {t("offset3")}
                  </li>
                  <li
                    className={`step-item ${
                      highlightedIndex === 4 ? 'selected' : ''
                    }`}
                    onClick={() => highlightListItem(4)}
                  >
                    {t("offset4")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="offsetdisconnected-right">
            <div className="slider">
              <span
                style={{ '--i': 1 }}
                className={highlightedIndex === 1 ? 'highlighted' : ''}
              >
                <img src={OffsetWeb2} alt="Image1" style={{ width: '400px', height: '200px'}}/>
              </span>
              <span
                style={{ '--i': 2 }}
                className={highlightedIndex === 2 ? 'highlighted' : ''}
              >
                <img src={Image2} alt="Image2" style={{ width: '270px', height: '400px' }}/>
              </span>
              <span
                style={{ '--i': 3 }}
                className={highlightedIndex === 3 ? 'highlighted' : ''}
              >
                <img src={ImageRetire2} alt="Image3" style={{ width: '270px'}} />
              </span>
              <span
                style={{ '--i': 4 }}
                className={highlightedIndex === 4 ? 'highlighted' : ''}
              >
                <img src={GPcertificate} alt="Image4" style={{ width: '280px',  /*boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.5)'*/ }}/>
                <div className="bubble">PDF + SBT Token</div>
              </span>
            </div>
          </div>
        </div>
      )}
            { isKYC === "true" && isLoading === true && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                </div>
            )}
            { isKYC === "true" &&  isBalance === true && isLoading === false &&(
              <>
              <div className='radio-inputs'>
              <label className="radio">
              <input type="radio" name="radio" className="radio-inputs-selected"></input>
              <span className="radio-inputs-selected name">{t("cc")}</span>
              </label>
              </div>
              <div className="nft-list gap-10">
                {tokenList.map((token, index) => (
                  <OffsetCarbonCredits key = {index} index={index} />
                ))}
                </div>
                </>
            )}
            { isKYC === "true" &&  isBalance === false && isLoading === false &&(
              <div className='input-number2'>
              <div className='blackandwhite-offset'>  
                      <img className='nft-img' src={BlackWhite} alt="TokenImage"/>
                    </div>
                <div className='nft-info-advertencia'>
            {/* <img src={adv}></img> */}
            <p className="intro-text-offset" style={{ textAlign: 'center', maxWidth: '500px', padding: '10px'}}>{t("offset-text-cc")}</p>
            </div>
            </div>
            )}
      <ToastContainer position="top-left"   style={{ marginTop: '130px' }}/>
      </div>
    </div>
  );
}

export default Offset;
import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function Feedback() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  // const [userInformation, setUserInformation] = useState({});
  const connectedAddress = sessionStorage.getItem('connectedAddress2');
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);

  useEffect(() => {
    // Fetch user information based on the connected address
    
    // Create a new Headers object
    const headers = new Headers();
    headers.append("Origin", "https://app.greenpls.com");
    
    fetch(`https://api.greenpls.com/api.php/getUserByAddress?address=${connectedAddress}`, {
      method: 'GET',
      headers: headers // Pass the Headers object in the request options
    })
      .then((res) => res.json())
      .then((data) => {
        // Update the user information state with the retrieved data
        // setUserInformation(data);
        setName(data.name);
        setEmail(data.email);
        setAddress(connectedAddress);
      });
      }, [connectedAddress]);

  const handleSubmit = (e) => {
    e.preventDefault();

        // Validate feedback and rating
        if (!feedback.trim() || rating === 0) {
            toast.error(t("feedback-fields"), { toastId: 'errorToast' });
            return;
          }

        // Create a data object to send to the server
        const data = {
            name,
            email,
            address,
            feedback,
            rating
        };


    // Add CORS headers
    const headers = new Headers();
    headers.append("Origin", "https://app.greenpls.com");

    fetch('https://api.greenpls.com/api.php/sendfeedback', {
      method: 'POST',
      crossDomain: true,
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('API Response:', data);
        if (data.error) {
          // Display error message from the server
          toast.error(data.error, { toastId: 'errorToast' });
        } else if (data.success) {
          // Clear form fields
          setFeedback('');
          setRating(0);
        // Reset input values to placeholders
        document.getElementById('feedback').value = '';
        // Clear the selection of all radio buttons in the star rating group
        const radioButtons = document.getElementsByName('rating');
        radioButtons.forEach((radioButton) => {
          radioButton.checked = false;
        });
          // Display success message
          toast.success(t("feedback-succesfull"), { toastId: 'successToast' });
          //Go to Home Page
          setTimeout(() => {
            // Redirect to the home page
            window.location.href = '/'; // Replace '/' with the actual URL of your home page
          }, 2000);
        } else {
          // Display generic error message
          toast.error(t("toast-error"), { toastId: 'errorToast' });
        }
      })
      .catch((error) => {
        // console.log(error);
        // Display error message for network or API-related errors
        toast.error(t("toast-error"), { toastId: 'errorToast' });
      });
  };
  

  return (
    <div className="body-top">
      <NavBar />
      <div className='App-content'>
      <div className="body-signup">
        <div className="signup-form2">
          <div className="profile_top">
            {/* <h2> {t("Registro")} </h2> */}
            <h2> Feedback </h2>
          </div>
          {/* <p className='intro-text'>{t("KYC-texto")}</p> */}
          <p className='intro-text'>{t("text-feedback")}</p>
          <div>
          <div className="contenido">
          <form className="form" onSubmit={(e) => {handleSubmit(e);}}>
            <textarea placeholder={t("feedback-placeholder")} id="feedback"  onChange={(e)=>setFeedback(e.target.value)}></textarea>
            <div className="rating">
              <input name="rating" id="star5" type="radio" value="5" onChange={(e) => setRating(e.target.value)} />
              <label htmlFor="star5"></label>
              <input name="rating" id="star4" type="radio" value="4" onChange={(e) => setRating(e.target.value)} />
              <label htmlFor="star4"></label>
              <input name="rating" id="star3" type="radio" value="3" onChange={(e) => setRating(e.target.value)} />
              <label htmlFor="star3"></label>
              <input name="rating" id="star2" type="radio" value="2" onChange={(e) => setRating(e.target.value)} />
              <label htmlFor="star2"></label>
              <input name="rating" id="star1" type="radio" value="1" onChange={(e) => setRating(e.target.value)} />
              <label htmlFor="star1"></label>
            </div>
            <button>{t("feedback-button")}</button>
          </form>
            </div> 
            </div>         
            </div>
            </div>
            <ToastContainer position="top-left" style={{ marginTop: '130px'}} />
            </div> 
            </div>
  );
  }

  export default Feedback;
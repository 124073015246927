import React, { useEffect, useState, useRef } from "react";
// import { React } from 'react';
import { ethers, BigNumber } from 'ethers';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import NavBar from './NavBar'; 
import Matic from './img/polygon-matic-logo.png';
import TransactionTable2 from "./TransactionTable2";
import "./transactiontable.css";
import { useTranslation } from 'react-i18next';
import copy from './img/clipboard.svg';
import image1 from './img/GAN1.png';
const connectWallet = 'Connect Wallet and pass KYC before buying';
const connectedAddress = sessionStorage.getItem('connectedAddress');
const connectedAddress2 = sessionStorage.getItem('connectedAddress2');
const isConnected = Boolean(connectedAddress);
const chain = sessionStorage.getItem('chain');


const MainMint2 = () => {
    const [mintAmount, setMintAmount] = useState(1);
    const [mintAmount2, setMintAmount2] = useState(1);
    const {index} = useParams();
    const financeList = require('./financeList');
    const abiList = require('./abiFin');
    const CIDlist = require('./CIDlistFin'); 
    const tokenAddress = financeList[index];
    const abi = abiList[index];
    const image = image1;
    const [price, setPrice] = useState(null);
    const [tokenName, setName] = useState(null);
    const [maxSupply, setMaxSupply] = useState(null);
    const [totalSupply, setTotalSupply] = useState(null);
    const [available, setAvailable] = useState(null);
    const [description, setDescription] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [attributesMap, setAttributesMap] = useState([]);
    const [isGenuine, setIsGenuine]= useState(true);
    const [showToast,setShowToast] = useState (true);
    const compensation = 'Your request has been sent';
    const buy = 'Your deposit has been completed';
    const withdraw = 'Your withdrawal has been completed';
    const addressUrl = "https://amoy.polygonscan.com/address/" + financeList[index];
    const { t } = useTranslation();
    const buttonRef1 = useRef();
    const buttonRef2 = useRef();
    const contRef1 = useRef();
    const contRef2 = useRef();

    

    function sliceAddress(text){
        if (text.length>40){
            const newtext = text.slice(0, 4) + '...' + text.slice(-4);
            return newtext
        }
        else {
            return text
        }
    }
    // useEffect (() => {
    //     if (showToast){
    //         toast.info(t("toast-financiar"));
    //         setShowToast(false);
    //     }
    // },[showToast]);

    useEffect (() => {
        HandleInfo();
        async function fetchTokenMetadataFromIPFS() {
            try {
              const metadataURL = `https://gateway.pinata.cloud/ipfs/${CIDlist[index]}`;
              const response = await fetch(metadataURL);
              const metadata = await response.json();
        
              const imageUrl = metadata.image;
              const finalImageUrl = imageUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
              const attributes = metadata.attributes;
              const attributesMap = attributes.map(attribute => (
                <div className="nft_attributes_block-content-item" key={attribute.trait_type}>
                  <p className="nft_block-info-title">{attribute.trait_type}</p>
                  <p className="nft_attributes_block-content-item-subtitle">{sliceAddress(attribute.value)}</p>
                </div>
              ));
              const tokenName = metadata.name;
              const description = metadata.description;
              const desiredTrait = attributes.find((attribute) => attribute.trait_type === "CO2 Emissions Saved (tCO2)");
              setDescription(description);
              setName(tokenName);
            //   setImage(finalImageUrl);
              setAttributesMap(attributesMap);
              setMaxSupply(desiredTrait.value);
        
              console.log('IPFS data loaded');
            } catch (error) {
              console.log('Error fetching token metadata from (Pinata)IPFS:', error);
              try {
                const metadataURL = `https://ipfs.io/ipfs/${CIDlist[index]}`;
                const response = await fetch(metadataURL);
                const metadata = await response.json();
          
                const imageUrl = metadata.image;
                const finalImageUrl = imageUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const attributes = metadata.attributes;
                const attributesMap = attributes.map(attribute => (
                  <div className="nft_attributes_block-content-item" key={attribute.trait_type}>
                    <p className="nft_block-info-title">{attribute.trait_type}</p>
                    <p className="nft_attributes_block-content-item-subtitle">{sliceAddress(attribute.value)}</p>
                  </div>
                ));
                const tokenName = metadata.name;
                const description = metadata.description;
                const desiredTrait = attributes.find((attribute) => attribute.trait_type === "CO2 Emissions Saved (tCO2)");
                setDescription(description);
                setName(tokenName);
                // setImage(finalImageUrl);
                setAttributesMap(attributesMap);
                setMaxSupply(desiredTrait.value);
          
                console.log('IPFS data loaded');
              } catch (error) {
                console.log('Error fetching token metadata from (Cloudfare)IPFS:', error);
              }
            }  
          }
        
          fetchTokenMetadataFromIPFS();
    },[]);
    
    async function HandleInfo () {
        let provider
        if (chain == "80002"){
            provider = new ethers.providers.Web3Provider(window.ethereum);
          } else {
            provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414');
          }
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                provider
            );
            try {
                //Variables
                const tokenPrice = await contract.price();
                const tokenPriceMatic = ethers.utils.formatEther(tokenPrice);
                const totalSupply = await contract.totalBalance();
                const maxSupply = await contract.maxSupply();
                if (isConnected==true){
                    const userBalance = await contract.userBalances(connectedAddress2);
                    setUserBalance(userBalance);
                }
                const available = maxSupply-totalSupply;
                setIsGenuine(isGenuine);
                setPrice(tokenPriceMatic);
                setAvailable(available);
                setTotalSupply(totalSupply);
                console.log('Blockchain data loaded');

            } catch (err) {
                console.log("error: ", err)
            }
        }
    

    async function handleDeposit() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                signer
            );
            try {
                const price = await contract.price();
                const response = await contract.addLiquidity(BigNumber.from(mintAmount), {
                    value: ((price * mintAmount).toString()),
                });
                toast.info('Wait for deposit confirmation. (May take up to 10-20 seconds)');
                await provider.waitForTransaction(response.hash);
                await HandleInfo();
                console.log('Transaction sent');
                toast.success(buy);
                

            } catch (error) {
                console.log('error', error);
                const message = error.reason.substring(20);
                if (message == 'ction'){
                    toast.error (error.reason);
                }
                else{
                    toast.error (message);
                }
                
            }
        }
    }
    async function handleWithdraw() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                tokenAddress,
                abi,
                signer
            );
            try {
                const price = await contract.price();
                const response = await contract.withdraw(mintAmount2);
                toast.info('Wait for withdraw confirmation. (May take up to 10-20 seconds)');
                await provider.waitForTransaction(response.hash);
                await HandleInfo();
                console.log('Transaction sent');
                toast.success(withdraw);
                

            } catch (error) {
                console.log('error', error);
                const message = error.reason.substring(20);
                if (message == 'ction'){
                    toast.error (error.reason);
                }
                else{
                    toast.error (message);
                }
                
            }
        }
    }

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        const newValue = parseInt(mintAmount) - 1;
        setMintAmount(newValue);
    };

    const handleIncrement = () => {
        const newValue = parseInt(mintAmount) + 1;
        setMintAmount(newValue);
    };

    const handleDecrement2 = () => {
        if (mintAmount2 <= 1) return;
        const newValue = parseInt(mintAmount2) - 1;
        setMintAmount2(newValue);
    };

    const handleIncrement2 = () => {
        const newValue = parseInt(mintAmount2) + 1;
        setMintAmount2(newValue);
    };

    const ClaimCompensation = () => {
        toast.success(compensation);
    };

    const ConnectWallet = () => {
        toast.info(connectWallet);
    };

    async function copyAddress() {
        try {
          await navigator.clipboard.writeText(tokenAddress);
          toast.success('Address copied to clipboard');
          /* Resolved - text copied to clipboard successfully */
        } catch (err) {
          console.error('Failed to copy: ', err);
          /* Rejected - text failed to copy to the clipboard */
        }
    }

    const changeForm = () => {
        buttonRef1.current.classList.toggle('signup-options_act');
        buttonRef2.current.classList.toggle('signup-options_act');
        contRef1.current.classList.toggle('content_act');
        contRef2.current.classList.toggle('content_act');
      };

    return (
        <div className="body-top">
            <NavBar/>
                <div>
                <div className="App-content">
                        <div className="nft_block">
                            <div className="nft_block-info">
                                <div>
                                <div className="nft_block-info-collection">
                                    <img src={image} alt="Matic" className='nft_block-info-collection-icon' />
                                    {/* <h1 className='nft_block-info-collection-name'>{tokenName}</h1> */}
                                    <h1 className='nft_block-info-collection-name'>Ganadería Sostenible Lacteos S.A</h1>
                                </div>
                                { isGenuine ? (
                                <img src={image} alt="Token image" className = "nft_block-image"/>
                                ) : (
                                    <div>
                                        <img src={image} alt="Token image" className = "nft_block-image-fraud"/>
                                    </div>
                                    )}
                                </div>
                                    <div className="nft_block-info-description">
                                        <p>{description}</p>
                                    </div>
                                    <div className='nft-info-price copyaddress'>
                                        <img src={copy} onClick={copyAddress} className="pointer"></img>
                                        <p className="nft_block-info-title">{t("copy-address")}</p>
                                    </div>
                                    <div class="info">
                                        <div class="info__icon">
                                        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.5c-5.79844 0-10.5 4.70156-10.5 10.5 0 5.7984 4.70156 10.5 10.5 10.5 5.7984 0 10.5-4.7016 10.5-10.5 0-5.79844-4.7016-10.5-10.5-10.5zm.75 15.5625c0 .1031-.0844.1875-.1875.1875h-1.125c-.1031 0-.1875-.0844-.1875-.1875v-6.375c0-.1031.0844-.1875.1875-.1875h1.125c.1031 0 .1875.0844.1875.1875zm-.75-8.0625c-.2944-.00601-.5747-.12718-.7808-.3375-.206-.21032-.3215-.49305-.3215-.7875s.1155-.57718.3215-.7875c.2061-.21032.4864-.33149.7808-.3375.2944.00601.5747.12718.7808.3375.206.21032.3215.49305.3215.7875s-.1155.57718-.3215.7875c-.2061.21032-.4864.33149-.7808.3375z" fill="#393a37"></path></svg>
                                        </div>
                                        <div class="info__title">{t("toast-financiar")}</div>
                                        {/* <div class="info__close"><svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20"><path fill="#393a37" d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"></path></svg></div> */}
                                    </div>
                            </div>
                                <div className="nft_block-info">
                                {/* <p>{tokenAddress}</p> */}
                                <div className="signup-options" style={{marginTop: '60px'}}>
                                    { <React.Fragment>
                                    <button ref={buttonRef1} onClick={changeForm} className="signup-options_act">{t("fin-deposita")}</button>
                                    <button ref={buttonRef2} onClick={changeForm}>{t("fin-retira")}</button> 
                                    </React.Fragment>}
                                </div>
                                <div ref={contRef1} className="content content_act">
                                    <div className="nft_block-info-box" style={{marginTop:'unset'}}>
                                        <div className="nft_block-info-price">
                                            <div className="nft_block-info-price-matic">
                                            <img src={Matic} alt="Matic" />
                                            <p>{price ? `  ${price * mintAmount}` : 'Not available'} </p>
                                            </div>
                                        </div>
                                        <div className="input-number">
                                            <button id = "decrement" onClick={handleDecrement}>-</button>
                                            <input type="number" value={mintAmount} onChange={(e) => setMintAmount(e.target.value)}/>
                                            <button id = "increment" onClick={handleIncrement}>+</button>
                                        </div>
                                        { isConnected ? (
                                        <button className="nft_block-info-buy" onClick={handleDeposit}>{t("fin-deposita")}</button>
                                        ) : (
                                        <button className="nft_block-info-buy" onClick={ConnectWallet}>{t("fin-deposita")}</button>
                                        )}
                                    </div>
                                </div>
                                <div ref={contRef2} className="content">
                                        <p className="nft_block-info-quantity posicion">{t("fin-posición")}</p>
                                        <div className="nft_block-info-box" style={{marginTop: "unset"}}>
                                        <div className="nft_block-info-price">
                                            <div className="nft_block-info-price-matic">
                                            {/* <img src={Matic} alt="Matic" /> */}
                                            <p style={{marginLeft:"35px"}}>{userBalance ? `    ${userBalance} ` : 'Not connected'}  </p>
                                            </div>
                                        </div>
                                        <div className="input-number">
                                            <button id = "decrement" onClick={handleDecrement2}>-</button>
                                            <input type="number" value={mintAmount2} onChange={(e) => setMintAmount2(e.target.value)}/>
                                            <button id = "increment" onClick={handleIncrement2}>+</button>
                                        </div>
                                        { isConnected ? (
                                        <button className="nft_block-info-buy" onClick={handleWithdraw}>{t("fin-retira")}</button>
                                        ) : (
                                        <button className="nft_block-info-buy" onClick={ConnectWallet}>{t("fin-retira")}</button>
                                        )}
                                    </div>
                                </div>
                                            {/* <p className="nft_block-info-quantity">Progreso: 4800 de 24000</p> */}
                                            <p className="nft_block-info-quantity"> {available && maxSupply ? `${available} of ${maxSupply} available` : 'Loading available supply...'}</p>
                                            <progress class="progress progress-success w-56" value={totalSupply} max="100"></progress>
                                            
                                            <div className="nft_block-info-list">
                                                <p>{attributesMap}</p>
                                            </div>   
                                            <div className="nft_block-info-address">
                                                <a className="contract-addr-button" href={addressUrl} target="_blank">{t("contrato")}</a>
                                            </div>
                                </div>
                        </div>
                            <div className='transaction-table-container'>
                                <h3>{t("transacciones")}</h3>
                                <TransactionTable2  selectedindex = {index} totalSupply = {totalSupply}/>
                            </div>
                            
                            <ToastContainer position="top-left"   style={{ marginTop: '130px' }}/> 
                </div>
                </div>
        </div>  
   
);
};

export default MainMint2;
import {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import {
    Link,
  } from "react-router-dom";
import Matic from './img/polygon-matic-logo.png';
import { useTranslation } from 'react-i18next';
import image2 from './img/EolicaEspana1.png';
import image4 from './img/SolarSpain1.png';
import image3 from './img/WorldReforestation1.png';
import image1 from './img/BioOil1.png';
  
const TokenInfoProfile = (props) => {
    
    const tokenList = require('./tokenListCarbonCredits');
    const abiList = require('./abiListCarbonCredits');
    const CIDlist = require('./CIDlist'); 
    const newTo = {
        pathname:"/MintCarbonCredit/"+ props.index
    }
    const tokenAddress = tokenList[props.index];
    const abi = abiList[props.index];
    const [price, setPrice] = useState(null);
    const [name, setName] = useState(null);
    const [isGenuine, setIsGenuine] = useState(true);
    const { t } = useTranslation();
    const chain = sessionStorage.getItem('chain');
    const images = [image1, image2, image3, image4];
    const image = images[props.index];

    useEffect (() => {
        async function HandleInfo () {
            if (window.ethereum) {
                let provider;
                if (chain === "80002"){
                  provider = new ethers.providers.Web3Provider(window.ethereum);
                } else {
                  provider = new ethers.providers.JsonRpcProvider('https://polygon-amoy.infura.io/v3/c52aca5a60ef4b9f826dbda70e733414'); 
                  }
                const contract = new ethers.Contract(
                    tokenAddress,
                    abi,
                    provider
                );
                try {
                    //Variables
                    const tokenPrice = await contract.tokenPrice();
                    const tokenPriceMatic = ethers.utils.formatEther(tokenPrice);
                    const isGenuine = await contract.isGenuine();
                    //imagen                      
                    setIsGenuine(isGenuine);
                    setPrice(tokenPriceMatic);
                    // console.log('Blockchain data loaded');

                } catch (err) {
                    // console.log("errroe: ", err)
                }
            }
        }
        HandleInfo();
        async function fetchTokenMetadataFromIPFS() {
            try {
              const metadataURL = `https://gateway.pinata.cloud/ipfs/${CIDlist[props.index]}`;
              const response = await fetch(metadataURL);
              const metadata = await response.json();
              const imageUrl = metadata.image;
              const finalImageUrl = imageUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
              const tokenName = metadata.name;
                setName(tokenName);

                // console.log('IPFS data loaded');
                } catch (error) {
                // console.log('Error fetching token metadata from IPFS:', error);
                try {
                    const metadataURL = `https://ipfs.io/ipfs/${CIDlist[props.index]}`;
                    const response = await fetch(metadataURL);
                    const metadata = await response.json();
                    const imageUrl = metadata.image;
                    const finalImageUrl = imageUrl.replace('ipfs://', 'https://ipfs.io/ipfs/');
                    // console.log(finalImageUrl);
                    const tokenName = metadata.name;
                    setName(tokenName);
                    // console.log('IPFS data loaded');
                    } catch (error) {
                    // console.log('Error fetching token metadata from Cloudflare(IPFS):', error);
                    }
                }
                
        }
        fetchTokenMetadataFromIPFS();
    },[]);

      const getIsGenuine = () => {
        if (isGenuine === true) {
          return (
        <div className='mint-window'>
            <div className='green-dot'></div>
            <p className="openWindow-text">{t("genuino")}</p>
        </div>
        );
        } else {
            return (
                <div className='mint-window'>
                    <div className='red-dot'></div>
                    <p className="closedWindow-text">{t("fraudulento")}</p>
                </div>
            );
        }
      }  
      
      
    function formatPrice(price, decimalPlaces) {
        const formattedPrice = (price).toFixed(decimalPlaces);
        return parseFloat(formattedPrice).toString(); // Remove trailing zeros
    }
    
        return (
        <div>
            <div className='nft-state'>
                <div>{getIsGenuine()}</div>
                
                <span className='quantity'>{`${props.tokenBalance}`}</span>
                <div className='nft'>
                <Link to={newTo}>
                    { isGenuine ? (
                        <img className='nft-img' src={image} alt="TokenImage"/>
                        ) : (
                            <div>
                                <img className='nft-img-fraud' src={image} alt="TokenImage"/>
                            </div>
                            )}
                    <div className='nft-info'>
                        <p>{name}</p>
                        <div className='nft-info-bottom'>
                            { isGenuine ? (
                            <div className='nft-info-price-box'>
                                <div className='nft-info-price'>
                                <p>{t("valor")}</p>
                                </div>
                            <div className='nft-info-price'>
                                <img src={Matic} alt="Matic" className='image' />
                                <p className='nft-info-price-text'>{price ?  ` ${formatPrice(price * props.tokenBalance, 5)}` : 'Not available'} </p>
                            </div>
                            </div>
                            ) : (
                            <div>
                                <p>{t("compensación")}</p>
                            </div>
                            )}
                        </div>
                    </div>      
                    </Link>
                </div>
                </div>
        </div>
            
        );
};

export default TokenInfoProfile;